import { Link } from 'react-router-dom';
import './ArticleCard.scss'
import React from 'react';
import EnvironmentConfig from '../../config/EnvironmentConfig';

interface CardProps {
    articleId: string | number;
    title: string;
    image: string;
    description: string;
    topicId: number | null;
}

const ArticleCard: React.FC<CardProps> = ({ title, image, articleId, description, topicId }) => {
    return (
        <div className='article-card-content-component'>
            <Link to={`/app/topic/${topicId}/article/${articleId}`} className='no-underline'>
                <div className='article-card-component'>
                    <img className='article-card-img' src={image ? image : `${EnvironmentConfig.imageBaseURL}cca_default_image.png`} alt='article' />
                    <div className='article-card-content'>
                        <span className='article-card-name'>{title}</span>
                        <span className='article-card-description'>{description}</span>
                        <Link to={`/app/topic/${topicId}/article/${articleId}`} className='no-underline'><span className="card-link">Read more</span></Link>
                    </div>
                </div>
            </Link>
        </div>
    )
}

export default ArticleCard