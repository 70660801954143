import React, { useEffect, useState } from 'react';
import './ScheduleTable.scss';
import HtmlTooltip from '@mui/material/Tooltip';
import { generateDateForDay } from '../../../utils/helperFunctions';
import { AnswerType } from '../JournalEntry/JournalEntry';
import { ScheduleData } from '../../../interfaces/ApiTypes';

interface TableProps {
  answers: AnswerType[];
  scheduleData: ScheduleData | null;
}

interface TimeSlot {
  time: string;
  activities: Record<string, { activity: string; moodRating: number } | null>;
}

const ScheduleTable: React.FC<TableProps> = ({ answers, scheduleData }) => {
  const [activities, setActivities] = useState<TimeSlot[]>([]);

  const getActivityString = (answer: any): string => {
    if (typeof answer === 'string') return answer;
    if (typeof answer === 'number') return answer.toString();
    if (answer && typeof answer === 'object') {
      if (answer.date) return new Date(answer.date).toLocaleDateString();
      if (answer.time) return answer.time;
    }
    return '';
  };

  useEffect(() => {
    if (!scheduleData?.headers || !scheduleData?.answers) {
      return;
    }

    const initialActivities: TimeSlot[] = scheduleData?.answers?.map((slot) => ({
      time: slot.timeSlot,
      activities: scheduleData?.headers?.reduce((acc, day) => {
        acc[day] = null;
        return acc;
      }, {} as Record<string, { activity: string; moodRating: number } | null>)
    }));

    const findDynamicKeys = (values: Array<Record<string, any>>) => {
      for (const value of values) {
        if (Object.keys(value).length > 0) {
          return Object.keys(value);
        }
      }
      return [];
    };

    const dynamicKeys = findDynamicKeys(scheduleData?.answers?.flatMap(a => a.values));

    const situationTriggerKey = dynamicKeys.find(key =>
      key.toLowerCase().includes('situation') || key.toLowerCase().includes('trigger')
    );

    const moodRatingKey = dynamicKeys.find(key =>
      key.toLowerCase().includes('mood') && key.toLowerCase().includes('rating')
    );

    const situationTrigger = situationTriggerKey
      ? getActivityString(
          answers?.find((item) => item.question === situationTriggerKey)?.answer || ''
        )
      : '';

    const moodRating = moodRatingKey
      ? parseInt(
          answers?.find((item) => item.question === moodRatingKey)?.answer as string || '0',
          10
        )
      : 0;

    const updatedActivities = initialActivities.map((slot, index) => {
      const slotValues = scheduleData?.answers[index]?.values;
      const updatedSlotActivities = { ...slot.activities };

      scheduleData?.headers?.forEach((day, dayIndex) => {
        const dayData = slotValues[dayIndex];
        if (dayData && Object.keys(dayData).length > 0) {
          const activityKey = Object.keys(dayData)[0];
          updatedSlotActivities[day] = {
            activity: dayData[activityKey] || situationTrigger,
            moodRating: dayData[moodRatingKey || activityKey] || moodRating,
          };
        }
      });

      return {
        ...slot,
        activities: updatedSlotActivities,
      };
    });

    setActivities(updatedActivities);
  }, [answers, scheduleData]);

  return (
    <div className="schedule-table-component">
      <span className="input-title">Your current week</span>
      <table>
        <thead className="table-head">
          <tr>
            <th className="day-title"></th>
            {scheduleData?.headers?.map((day, index) => (
              <th key={`header-${index}`} className="day-header">{day}</th>
            ))}
          </tr>
        </thead>
        <tbody className="table-body">
          {activities.map((slot, index) => (
            <tr key={`slot-${index}`}>
              <td className="time-slot-header">{slot.time}</td>
              {scheduleData?.headers?.map((day, dayIndex) => {
                const dayData = scheduleData?.answers[index]?.values[scheduleData?.headers?.indexOf(day)] || {};

                return (
                  <HtmlTooltip
                    key={`tooltip-${index}-${dayIndex}`}
                    placement="right-end"
                    enterDelay={100}
                    title={
                      <div className="cell-tooltip" style={{ pointerEvents: 'auto' }}>
                        <span className="day-info">{day} {generateDateForDay(day)}; {slot.time}</span>
                        {Object.entries(dayData).length > 0 ? (
                          Object.entries(dayData).map(([key, value]) => (
                            <span key={`tooltip-content-${key}`} className="tooltip-content">
                              {key}: {getActivityString(value)}
                            </span>
                          ))
                        ) : (
                          <span className="tooltip-content">No data available</span>
                        )}
                      </div>
                    }
                  >
                    <td
                      key={`time-slot-${index}-${dayIndex}`}
                      className="time-slot"
                      data-day={day}
                      data-time={slot.time}
                    >
                      {Object.entries(dayData).length > 0 &&
                        Object.entries(dayData).map(([key, value]) => (
                          <span key={`time-slot-content-${key}`}>
                            {key}: {getActivityString(value)}
                          </span>
                        ))}
                    </td>
                  </HtmlTooltip>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleTable;
