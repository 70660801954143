import './UserProfileMenu.scss'
import { useNavigate } from "react-router-dom";
import { logout } from "../../../utils/ApiClient";
import { clearLocalStorageExceptOID } from "../../../utils/clearStorageData";
import { storageKeys } from "../../../utils/constants";
import useGlobalState from '../../../context/useGlobalState';
import React from 'react';

interface ProfileProps {
    setOpenProfileMenu: (menu: boolean) => void;
}

const UserProfileMenu: React.FC<ProfileProps> = ({ setOpenProfileMenu }) => {

    const { globalErrorHandler } = useGlobalState()
    const navigate = useNavigate();

    const handleLogout = async (): Promise<void> => {
        try {
            await logout();
            clearLocalStorageExceptOID(storageKeys.OrganizationID);
            navigate("/login");
        } catch (error) {
            globalErrorHandler(error);
        }
    };

    const handleYourProfileButton = () => {
        setOpenProfileMenu(false)
        navigate('/app/account-info')
    }

    return (
        <div className='user-profile-menu-component' onMouseLeave={() => setOpenProfileMenu(false)}>
            <span className='menu-options' onClick={handleYourProfileButton}>Your Profile</span>
            <span className='menu-options'>Account Settings</span>
            <span className='menu-options' onClick={handleLogout} >Sign Out</span>
        </div>
    )
}

export default UserProfileMenu