import React from "react";
import FixedHeaderUserLayout from "../../component/layout/FixedHeaderUserLayout";
import Module from "./Module";

const index: React.FC = () => {
  return (
    <FixedHeaderUserLayout children={<Module />} />
  );
};

export default index;
