import { getLesson } from "../utils/ApiClient";
import useGlobalState from "../context/useGlobalState";
import { ApiLesson } from "../interfaces/ApiTypes";
import { useState } from "react";
import axios from "axios";
import useHandleUrlIdError from "../utils/handleUrlIdError";
import { useNavigate } from "react-router-dom";

export const FetchApiData = (): {
    fetchLesson: (moduleId: number, id: number | null) => void;
    lessonDetails: ApiLesson | null;
    isLessonFetched: boolean;
    currentLessonId: number | null | undefined;
    setCurrentLessonId: (id: number | null) => void;
} => {
    const { globalErrorHandler, setLoader } = useGlobalState()
    const [currentLessonId, setCurrentLessonId] = useState<number | null>();
    const [lessonDetails, setLessonDetails] = useState<ApiLesson | null>(null)
    const [isLessonFetched, setIsLessonFetched] = useState<boolean>(false)
    const handleUrlIdError = useHandleUrlIdError();
    const navigate = useNavigate()

    const fetchLesson = async (moduleId: number, lessonId: number | null): Promise<void> => {
        if (!lessonId) return
        setIsLessonFetched(true)
        try {
            const response = await getLesson(lessonId)
            navigate(`/app/module/${moduleId}/lesson/${lessonId}`, { replace: true });
            setLessonDetails(response.data)
            setCurrentLessonId(response.data.id)
            setIsLessonFetched(false)
            setLoader(false)
        } catch (error) {
            setIsLessonFetched(false)
            setLoader(false)
            if (axios.isAxiosError(error)) {
                handleUrlIdError(error);
            } else {
                globalErrorHandler(error)
            }
        }
    }
    return { lessonDetails, fetchLesson, isLessonFetched, currentLessonId, setCurrentLessonId };
}