import React, { useState, useEffect, useRef } from 'react'
import './ChartedJournal.scss'
import ExerciseInput from '../Input/ExerciseInput'
import IncrementDecrementButton from '../Counter/IncrementDecrementButton'
import SmallGreenButton from '../Button/SmallGreenButton'
import ScheduleTable from '../Table/ScheduleTable'
import { ApiQuestions, ApiSubmittedEntries, ScheduleData } from '../../../interfaces/ApiTypes'
import { QuestionTypes } from '../../../utils/member-portal/constant'
import { AnswerType } from '../JournalEntry/JournalEntry'
import { isDateWithinCurrentWeek, validateChartedJournalAnswers } from '../../../utils/helperFunctions'
import { getExerciseData, submitExerciseAnswers } from '../../../utils/ApiClient'
import SuccessMessage from '../SuccessMessage/SuccessMessage'
import useGlobalState from '../../../context/useGlobalState'
import LoadingButton from '../Button/LoadingButton'
import DateAndTimeSlot from './DateAndTimeSlot'

interface ChartedJournalProps {
    moduleId: number;
    entries: ApiSubmittedEntries[];
    lessonId: number,
    questions: ApiQuestions[],
    onLessonUpdated: (moduleId: number, lessonId: number) => void;
    exerciseType: string,
}

const ChartedJournal: React.FC<ChartedJournalProps> = ({ entries, lessonId, questions, exerciseType, onLessonUpdated, moduleId }) => {
    const [question, setQuestion] = useState<ApiQuestions[]>(questions);
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [successMessage, setSuccessMessage] = useState<string | null>("")
    const [tempAnswers, setTempAnswers] = useState<AnswerType[]>([])
    const [answers, setAnswers] = useState<AnswerType[]>([]);
    const { globalErrorHandler } = useGlobalState()
    const [loading, setLoading] = useState<boolean>(false)
    
    const [scheduleData, setScheduleData] = useState<ScheduleData | null>(null);
    const [isFormValid, setIsFormValid] = useState<boolean>(true); 
    const effectRef = useRef(false)

    const handleInputChange = (
        id: number,
        value: string | number | null | { date: Date | null, time: null | string },
        isRequired: boolean,
        question: string
    ) => {
        const formattedValue =
            value instanceof Date
                ? { date: value, time: null }
                : value;
        setError(false);
        const updatedAnswers = tempAnswers.map((answer: AnswerType) =>
            answer.question_id === id
                ? { ...answer, answer: formattedValue, isRequired: isRequired, question: question }
                : answer
        );
        setTempAnswers(updatedAnswers);
        const isValid = validateChartedJournalAnswers(updatedAnswers);
        if (!isValid) {
            setIsFormValid(true)
        } 
    };

    const getAnswerDefaultValue = (type: string) => {
        switch (type) {
            case QuestionTypes.dateAndTime:
                return { date: new Date(), time: '' };
            case QuestionTypes.longText:
                return '';
            case QuestionTypes.counter:
                return 0;
            default:
                return ''
        }
    }

    useEffect(() => {
        setQuestion(questions);
        if (entries?.length) {
            const allSubmittedAnswers = entries.flatMap((entry) => entry.submittedAnswers);
            setTempAnswers(
                allSubmittedAnswers.map((value) => ({
                    question: value.question,
                    question_id: value.id,
                    answer: value.answers?.answer
                        ? (
                            value.questionType === QuestionTypes.dateAndTime
                                ? {
                                    date: value.answers.answer.date ? new Date(value.answers.answer.date) : new Date(),
                                    time: value.answers.answer.time ? value.answers.answer.time : null,
                                }
                                : value.answers.answer
                        )
                        : getAnswerDefaultValue(value.questionType),
                    isRequired: value.isRequired,
                }))
            );
        } else {
            setTempAnswers(
                questions.map((value) => ({
                    question: value.question,
                    question_id: value.id,
                    answer: value.answers?.answer
                        ? (
                            value.questionType === QuestionTypes.dateAndTime
                                ? {
                                    date: value.answers.answer.date ? new Date(value.answers.answer.date) : new Date(),
                                    time: value.answers.answer.time ? value.answers.answer.time : null,
                                }
                                : value.answers.answer
                        )
                        : getAnswerDefaultValue(value.questionType),
                    isRequired: value.isRequired,
                }))
            );
        }
    }, [questions]);

    const fetchData = async () => {
        try {
            const response = await getExerciseData(lessonId);
            setScheduleData(response?.data);
        } catch (error) {
            globalErrorHandler(error)
        }
    };

    useEffect(() => {
        if (lessonId) {
            if (effectRef.current === false) {
                fetchData();
            }
            return () => {
                effectRef.current = true;
            };
        }
    }, [lessonId]);

    const handleSaveJournal = async (): Promise<void> => {
        if (!entries?.length) {
            const cleanedData = tempAnswers.map(({ question, isRequired, ...rest }) => {
                if (rest.answer && typeof rest.answer === 'object' && 'date' in rest.answer) {
                    return {
                        ...rest,
                        answer: {
                            date: rest.answer.date,
                            time: rest.answer.time
                        }
                    };
                }
                return rest;
            });

            const invalidDate = tempAnswers.some(
                (answer) =>
                    answer.answer &&
                    typeof answer.answer === 'object' &&
                    'date' in answer.answer &&
                    !isDateWithinCurrentWeek(answer.answer.date)
            );

            if (invalidDate) {
                setErrorMessage('Please select dates within the current week.');
                setError(true);
                return;
            }
            const hasError = validateChartedJournalAnswers(tempAnswers)
            if (!hasError) {
                setError(false)
                setLoading(true)
                setIsFormValid(true);
                try {
                    const response = await submitExerciseAnswers(lessonId, cleanedData);
                    setSuccessMessage(response.message)
                    
                    setTimeout(() => {
                        setLoading(false)
                        onLessonUpdated(moduleId, lessonId)
                    }, 3000)
                    if (response.status === 201) {
                        setAnswers([...tempAnswers]);                        
                    }
                } catch (error) {
                    globalErrorHandler(error);
                    setLoading(false)
                    setIsFormValid(true); 
                }
            } else {
                setErrorMessage(hasError);
                setError(true);
                setIsFormValid(false);
            }
        }
    };

    return (
        <div className='charted-journal-component'>
            {
                !entries?.length && <>
                    {
                        question?.map((item: ApiQuestions, index) => {
                            return <div key={"entry" + index}>
                                {
                                    item.questionType === QuestionTypes.dateAndTime && <>
                                        <div className='journal-header'>
                                            <div className='title-button-bar'>
                                                <span className='input-title'>Mood Entry</span>
                                            </div>
                                            <DateAndTimeSlot
                                                hideTitle={true}
                                                required={item.isRequired}
                                                title={item.question}
                                                hideButton={true}
                                                id={item.id}
                                                value={{
                                                    date: new Date(),
                                                    time: ''
                                                }}
                                                handleInputChange={handleInputChange}
                                                exerciseType={exerciseType}
                                                scheduleData={scheduleData}
                                            />
                                        </div>
                                    </>
                                }
                                {
                                    item.questionType === QuestionTypes.longText && <>
                                        {
                                            <>
                                                <ExerciseInput
                                                    handleInputChange={handleInputChange}
                                                    name={item.question}
                                                    required={item.isRequired}
                                                    id={item.id}
                                                    value={(item?.answers?.answer || '') as string}
                                                    title={item.question} />
                                            </>
                                        }
                                    </>
                                }
                                {
                                    item.questionType === QuestionTypes.counter && <>
                                        {
                                            <IncrementDecrementButton
                                                handleInputChange={handleInputChange}
                                                title={item.question}
                                                id={item.id}
                                                required={item.isRequired}
                                                answer={tempAnswers[index]}
                                            />
                                        }
                                    </>
                                }
                            </div>
                        })
                    }
                </>
            }
            {
                error && errorMessage && <span className='error'>{errorMessage}</span>
            }
            {
                successMessage && <SuccessMessage message={successMessage} />
            }
            {
                loading ? <LoadingButton /> : <SmallGreenButton name='Submit' onClick={handleSaveJournal} />
            }
            <ScheduleTable answers={answers} scheduleData={scheduleData} />
        </div>
    )
}

export default ChartedJournal