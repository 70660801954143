import React from 'react'
import './LongCradCommonstyles.scss'
import './ContentDescriptionCard.scss'
import WatchIcon from '../../assests/icons/clock.png'
import PersonIcon from '../../assests/icons/person.png'
import PlayIcon from '../../assests/icons/video-icon-grey.png'
import GreenProgressBar from '../Progressbar/GreenProgressBar'
import { Link } from 'react-router-dom'

interface CardProps {
    id: number;
    title: string;
    isStarted: boolean;
    content: string;
    drDetails: string;
    exercise: number;
    videos: number;
    totalMins: number;
    remainingMins: number | null;
    path: string;
    status: string;
    startButtonClick: () => void;
    disableButton?: boolean
}

const ContentDescriptionCard: React.FC<CardProps> = (props: CardProps) => {
    return (
        <div className='content-desc-component right-content-details'>
            <div className='button-header'>
                <span className='button-header-title'>{props.title}</span>
                {
                    props.isStarted ? <>
                        {
                            props.disableButton ? <button className='disable-border-button' disabled={props.disableButton}>RESUME</button> : <Link to={props.path} className='no-underline'>
                                <button className='green-border-button'>RESUME</button>
                            </Link>
                        }
                    </> : <button className='green-rounded-btn' onClick={props.startButtonClick}>START</button>
                }
            </div>
            <span className='dr-details'>{props.drDetails}</span>
            <div className='progressbar-box'>
                <GreenProgressBar status={props.status} />
            </div>
            <span className='details'>{props.content}</span>
            <div className='button-bar'>
                <div className='button-box'>
                    <img alt="count-icon" src={WatchIcon} className='count-icon' />
                    <span className='counts-text'>{props.totalMins}mins</span>
                </div>
                <div className='button-box'>
                    <img alt="play-icon" src={PlayIcon} className='count-icon' />
                    <span className='counts-text'>{props.videos} videos</span>
                </div>
                <div className='button-box'>
                    <img alt="count-icon" src={PersonIcon} className='count-icon' />
                    <span className='counts-text'>{props.exercise} exercises</span>
                </div>
            </div>
        </div>
    )
}

export default ContentDescriptionCard