import React, { useEffect, useState } from 'react'
import LoadingIcon from '../../assests/icons/loading.png'

const LoadingButton: React.FC = () => {
    const [isDataSaved, setIsDataSaved] = useState<boolean>(false)
    useEffect(() => {
        setTimeout(() => {
            setIsDataSaved(true)
        }, 500)
    }, [isDataSaved])

    return (
        <button className='green-border-button'>{isDataSaved ? "Saved!" : <img src={LoadingIcon} alt='loading' />}</button>
    )
}

export default LoadingButton