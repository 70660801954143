import React, { useEffect, useState } from 'react'
import './ModuleSections.scss'
import LessonsList from '../../../ModuleSection/LessonsList'
import { ApiLesson, Sections } from '../../../../../interfaces/ApiTypes'
import LeftArrowIcon from '../../../../../component/assests/icons/left-arrow-black.png'

interface ModuleSectionsProps {
    list: Sections;
    sectionId: number;
    setSectionId: (id: number | null) => void;
    currentLessonId: number | null | undefined
    handleOnLessonClick: (lessonId: number) => void
}

const ModuleSections: React.FC<ModuleSectionsProps> = ({ list, sectionId, handleOnLessonClick, setSectionId, currentLessonId }) => {

    const [isAnimating, setIsAnimating] = useState(false);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (list.id === sectionId) {
            setIsAnimating(true);
            setIsVisible(true);
        } else {
            setIsAnimating(true);
            setTimeout(() => setIsVisible(false), 300);
        }
    }, [list.id, sectionId]);

    const handleToggle = (id: number) => {
        if (id === sectionId) {
            setSectionId(null)
        } else
            setSectionId(id)
    }

    return (
        <div className='course-module-component'>
            <div className='module-title-row' onClick={() => handleToggle(list.id)}>
                <span className='module-title-header'>{list.name}</span>
                <img
                    src={LeftArrowIcon} 
                    alt="icon"
                    className={`module-header-icon ${list.id === sectionId ? 'rotate' : ''}`}
                />
            </div>
            {(list.id === sectionId || isVisible) && (
                <div
                    className={`module-course-list ${list.id === sectionId && isAnimating
                        ? "animate-in"
                        : isAnimating
                            ? "animate-out"
                            : ""
                        }`}
                    onAnimationEnd={() => setIsAnimating(false)}
                >
                    {list?.lessons?.map((item: ApiLesson, index: number) => (
                        <LessonsList
                            key={"item" + index}
                            item={item}
                            currentLessonId={currentLessonId}
                            handleOnLessonClick={handleOnLessonClick}
                        />
                    ))}
                </div>
            )}
        </div>
    )
}

export default ModuleSections