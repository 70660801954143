import React from "react";
import {
  getSimplifiedUserName,
  getFormattedDate,
} from "../../../utils/helperFunctions";
import "./RefferalList.scss";

interface RefferalListProps {
  refferals: any;
  title: string;
}

interface Refferal {
  user: any;
  unread_messages_count: number;
  createdAt: string;
}

const RefferalList: React.FC<RefferalListProps> = ({ refferals, title }) => {
  return (
    <div className="refferal-list-component">
      <span className="title">{title}</span>
      {refferals?.map((refferal: Refferal, index: number) => {
        return (
          <div className="refferal-div" key={index}>
            <div className="refferal-info">
              <span className="text-div">
                {getSimplifiedUserName(refferal.user.username)}
              </span>
              <span className="text-div">
                {getFormattedDate(refferal.createdAt)}
              </span>
            </div>
            <div className="button-div">
              <button className="view-button">{"View"}</button>
            </div>
            {refferal.unread_messages_count > 0 && (
              <div className="badge">{refferal.unread_messages_count}</div>
            )}
          </div>
        );
      })}
      {refferals.length === 0 && (
        <span className="no-conversation">{"No Refferals"}</span>
      )}
    </div>
  );
};

export default RefferalList;
