import './FAQ.scss'
import { Questions } from './FAQQuetions'
import FAQHeader from '../../sections/FAQHeader'
import UpArrowIcon from '../../component/assests/icons/gray-up-arrow.png'
import { useState } from 'react'

const FAQs = () => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const handleToggle = (index: number) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div className='faq-page'>
      <FAQHeader />
      <div className='questions-container'>
        {Questions.map((item, index) => (
          <div className='question-section' key={index + "quest"}>
            <div className='expandable-bar' onClick={() => handleToggle(index)}>
              <span className='question-title'>{item.question}</span>
              <img src={UpArrowIcon} alt='expand' className={`arrow ${expandedIndex === index ? 'rotate' : ''}`} />
            </div>
            {expandedIndex === index && (
              <>
                <div className='question-answer'>{item.answer}</div>
                <div className='list-section'>
                  {
                    item.list && item.list.map((item, index) => {
                      return <li className='question-answer' key={index + "list"}>{item}</li>
                    })
                  }
                  {
                    item.topics && Object.values(item.topics).map((topic, index) => {
                      return <div className='questions-container' key={"t-" + index}>
                        <span>{topic.title}</span>
                        <div className='list-section'>{
                          topic.list.map((item, index) => { return (<li key={index + "li"}>{item}</li>) })
                        }</div>
                      </div>
                    })
                  }
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default FAQs