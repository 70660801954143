import './Topic.scss'
import PlayIcon from '../../component/assests/icons/play.png'
import SlackIcon from '../../component/assests/icons/slack.png'
import BookIcon from '../../component/assests/icons/book.png'
import PeopleIcon from '../../component/assests/icons/two-people.png'
import ArticleCard from '../../component/common/Cards/ArticleCard'
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react'

import { getTopicDetails, getTopicArticleList } from '../../utils/ApiClient'
import { ApiArticlePreview, ApiCBTModule, CompleteSubCategoryData } from '../../interfaces/ApiTypes'
import Loader from '../../component/common/Loader/Loader'
import ModuleCard from '../../component/common/Cards/ModuleCard'
import useGlobalState from '../../context/useGlobalState'
import IndividualSupport from '../../sections/IndividualSupport'
import useHandleUrlIdError from '../../utils/handleUrlIdError'
import axios from 'axios'

const Topic = () => {
    const [modules, setModules] = useState<ApiCBTModule[]>([])
    const [subCategory, setSubCategory] = useState<CompleteSubCategoryData | null>(null);
    const [articleList, setArticleList] = useState<ApiArticlePreview[]>([]);
    const [loading, setLoading] = useState<boolean>(true)
    const { topicId } = useParams<{ topicId: string }>();
    const handleUrlIdError = useHandleUrlIdError();
    const { globalErrorHandler } = useGlobalState()
    const navigate = useNavigate()

    const fetchModuleData = async (): Promise<void> => {
        try {
            const [subCategoryResponse, articleListResponse] = await Promise.all([
                getTopicDetails(Number(topicId)),
                getTopicArticleList(Number(topicId)),
            ]);
            setModules(subCategoryResponse.data.cbtModules);
            setSubCategory(subCategoryResponse.data);
            setArticleList(articleListResponse.data.digitalContent);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            if (axios.isAxiosError(error)) {
                handleUrlIdError(error);
            } else {
                globalErrorHandler(error)
            }
        }
    };

    useEffect(() => {
        fetchModuleData();
    }, [topicId]);

    const handleNavigation = () => {
        navigate(`/app/module/${topicId}`)
    }

    return (
        <>{
            loading ? <Loader /> :
                <>
                    {
                        subCategory && <div className='topic-page'>
                            <img src={subCategory.thumbnailUrl} alt='cover-img' className='cover-image' />
                            <div className='container'>
                                <div className='page-header-section'>
                                    <span className='page-title'>{subCategory.name}</span>
                                    <div className='right-section'>
                                        <div className='action-buttons-grid' >
                                            <div className='action-buttons'>
                                                <img src={SlackIcon} alt='play-icon' className='mini-icons' />
                                                <span>{subCategory.moduleCount} Modules</span>
                                            </div>
                                            <div className='action-buttons'>
                                                <img src={PlayIcon} alt='play-icon' className='mini-icons' />
                                                <span>{subCategory.videoCount} Videos</span>
                                            </div>
                                            <div className='action-buttons'>
                                                <img src={BookIcon} alt='play-icon' className='mini-icons' />
                                                <span>{subCategory.articleCount} Articles</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='cards-container'>
                                    {
                                        modules.map((item: ApiCBTModule, index) => {
                                            return (
                                                <ModuleCard
                                                    key={index + "act_card"}
                                                    id={item.id}
                                                    title={item.name}
                                                    exercise={item.totalExercises}
                                                    videos={item.totalVideos}
                                                    totalMins={item.totalMinutes}
                                                    remainingMins={item.remainingMintues}
                                                    isStarted={item.isStarted}
                                                    drDetails={item.curatorName}
                                                    image={item.thumbnail}
                                                    content={item.description}
                                                    status={item.status}
                                                    startButtonClick={handleNavigation}
                                                />)
                                        })
                                    }
                                </div>
                                <div className='sub-header'>
                                    <img src={BookIcon} alt='digital-book' className='sub-header-icon' />
                                    <span className='sub-header-text'>Digital Content</span>
                                </div>
                                <div className='article-card-grid'>
                                    {
                                        articleList?.map((item, index) => {
                                            return <ArticleCard
                                                key={"card" + index}
                                                articleId={item.id}
                                                title={item.title}
                                                image={item.photo}
                                                description={item.description}
                                                topicId={Number(topicId)}
                                            />
                                        })
                                    }
                                </div>
                            </div>
                            <div className='resources-section'>
                                <IndividualSupport title='Resources' icon={PeopleIcon} />
                            </div>
                        </div>
                    }
                </>
        }</>
    )
}

export default Topic