import { createContext } from "react";
import { ApiCareSquadCategory } from "../interfaces/ApiTypes";

interface GlobalState {
  globalError: string;
  serviceType: string[] | null;
  loader: boolean;
  menu: boolean;
  categories: ApiCareSquadCategory[] | undefined;
  setServiceType: (serviceType: string[] | null) => void;
  setGlobalError: (error: string) => void;
  globalErrorHandler: (error: any) => void;
  setLoader: (loading: boolean) => void;
  setMenu: (menu: boolean) => void;
  setCategories: (categories: ApiCareSquadCategory[]) => void;
}

const GlobalContext = createContext<GlobalState | undefined>(undefined);

export type { GlobalState };
export default GlobalContext;
