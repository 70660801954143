import { storageKeys } from "./constants";
import { getSessionToken } from "./ApiClient";

export const RefreshToken = async (): Promise<any> => {
  const sessionToken = localStorage.getItem(storageKeys.SessionToken);
  const sessionExpiry = localStorage.getItem(storageKeys.SessionExpiry);

  if (!sessionToken || !sessionExpiry) {
    try {
      const response = await getSessionToken();
      localStorage.setItem(storageKeys.SessionToken, response.data.token);
      localStorage.setItem(
        storageKeys.SessionExpiry,
        response.data?.expires_at.toString()
      );
      return response.data.token;
    } catch (error) {
      return false;
    }
  }

  let parseTime = JSON.parse(sessionExpiry);
  let expiryTime = new Date(parseTime);
  let currentTime = new Date();
  const expiryFlag = expiryTime > currentTime;

  if (!expiryFlag) {
    try {
      const response = await getSessionToken();
      localStorage.setItem(storageKeys.SessionToken, response.data.token);
      localStorage.setItem(
        storageKeys.SessionExpiry,
        response.data?.expires_at.toString()
      );
      return response.data.token;
    } catch (error) {
      return false;
    }
  } else {
    return sessionToken;
  }
};
