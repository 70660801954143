import React from 'react'

interface ButtonProps {
    name : string
    onClick?: () => void;
    disabled?: boolean;
}

const SmallGreenButton: React.FC<ButtonProps> = ({ name, onClick, disabled }) => {
    return (
        <div className={`green-rounded-btn ${disabled ? 'disabled' : ''}`}  onClick={!disabled ? onClick : undefined} style={{ opacity: disabled ? 0.5 : 1 }}>{name}</div>
    )
}

export default SmallGreenButton