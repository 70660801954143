import LessonAndSidebar from '../../component/common/LessonAndSidebar/LessonAndSidebar'
import './Lesson.scss'

const Lesson = () => {
    return (
        <div className='lesson-page'>
            <LessonAndSidebar />
        </div>
    )
}

export default Lesson