import React, { useEffect, useState } from 'react'
import './ModuleSection.scss'
import UpArrowIcon from '../../../component/assests/icons/gray-up-arrow.png'
import { ApiLesson, Sections } from '../../../interfaces/ApiTypes'
import LessonsList from './LessonsList'
import { Link, useNavigate } from 'react-router-dom'
import { updateModuleProgress } from '../../../utils/ApiClient'
import { ModuleStatus } from '../../../utils/member-portal/constant'
import useGlobalState from '../../../context/useGlobalState'
import { findFirstIncompleteLesson } from '../../../utils/helperFunctions'

interface Props {
    moduleSectionList: Sections[];
    moduleId: number;
    startButtonClick: () => void;
    isStarted: boolean;
    disableResumeButton: boolean
}

const ModuleSection: React.FC<Props> = ({ moduleSectionList, moduleId, startButtonClick, isStarted, disableResumeButton }) => {
    const [collapsedSections, setCollapsedSections] = useState<Record<number, boolean>>({});
    const { globalErrorHandler } = useGlobalState()
    const nextLessonId = findFirstIncompleteLesson(moduleSectionList)?.lessonId

    useEffect(() => {
        if (moduleSectionList.length > 0) {
            setCollapsedSections({ 0: true });
        }
    }, [moduleSectionList]);

    const navigate = useNavigate()
    const toggleSectionCollapse = (index: number) => {
        setCollapsedSections((prevCollapsed) => ({
            ...prevCollapsed,
            [index]: !prevCollapsed[index],
        }));
    }

    const handleModuleProgress = async (lessonID: number): Promise<void> => {
        try {
            await updateModuleProgress(moduleId ?? null, ModuleStatus.inProgress)
            navigate(`/app/module/${moduleId}/lesson/${lessonID}`);
        } catch (error) {
            globalErrorHandler(error)
        }
    }

    return (
        <div>
            {
                moduleSectionList.map((item: Sections, index: number) => {
                    return <div className='module-section-component'
                        key={index + "course"}>
                        <div className='section-title-row' onClick={() => toggleSectionCollapse(index)}>
                            <div className='section-icon-box'>
                                <img src={ UpArrowIcon} alt='collased' className={`arrow ${collapsedSections[index] ? 'rotate' : ''}`} />
                                <span className='section-title'>{item.name}</span>
                            </div>
                            {
                                index === 0 && <>
                                    {
                                        isStarted ?
                                            <>
                                                {disableResumeButton ?
                                                    <button className="disable-border-button">RESUME</button> :
                                                    <Link to={`/app/module/${moduleId}/lesson/${nextLessonId}`} className='no-underline'>
                                                        <button className="green-border-button">RESUME</button></Link>}
                                            </>
                                            : <button className="green-rounded-btn" onClick={startButtonClick}>Start</button>
                                    }
                                </>
                            }
                        </div>
                        <div className='course-list'>
                            {
                                collapsedSections[index] && item?.lessons?.map((item: ApiLesson, index: number) => {
                                    return <div key={index + "item"} onClick={() => handleModuleProgress(item.id)}>
                                        <LessonsList item={item} />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                })
            }
        </div >
    )
}

export default ModuleSection