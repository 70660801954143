import { getServiceType } from "./ApiClient";
import { storageKeys } from "./constants";

export const getServices = async (oId: any, context: any) => {
  const { globalErrorHandler, setServiceType, setLoader }: any = context;
  try {
    const response = await getServiceType(oId);
    if (response.data.listOfAvailableService.length === 0) {
      setLoader(false);
      return
    }
    const responseArray = response.data.listOfAvailableService;
    const convertedArray = Array.isArray(responseArray)
      ? responseArray
      : [responseArray];
    setServiceType(convertedArray);
    localStorage.setItem(storageKeys.AvailableService, JSON.stringify(convertedArray));
    setLoader(false);
  } catch (error) {
    setLoader(false);
    globalErrorHandler(error);
  }
};
