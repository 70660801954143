import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { storageKeys } from "../utils/constants";

const WorkLifePageBlock = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const category = localStorage.getItem(storageKeys.Category);
    if (!category) {
      navigate("/app/worklife-form");
    }
  }, [navigate]);
  return (
    <div></div>
  )
}

export default WorkLifePageBlock