import ServiceCards from '../../component/common/ServiceComponent/ServiceComponent'
import './IndividualSupport.scss'
import { useNavigationHandler } from '../../utils/useNavigationHandler';
import { storageKeys } from '../../utils/constants';
import { ticketList } from '../../utils/ApiClient';
import useGlobalState from '../../context/useGlobalState';
import { useEffect, useState } from 'react';
import EnvironmentConfig from '../../component/config/EnvironmentConfig';

const IndividualSupport = () => {
  const [activeTickets, setActiveTickets] = useState<number | undefined>()
  const services = localStorage.getItem(storageKeys.AvailableService);
  const availableServices = services ? JSON.parse(services) : null;
  const { changedFlowNavigation } = useNavigationHandler();
  const { globalErrorHandler } = useGlobalState()

  const handleNavigation = (flow?: string) => {
    const flowName = flow || "";
    changedFlowNavigation(flowName);
  };

  const getActiveTicketCounts = async (): Promise<void> => {
    try {
      const response = await ticketList()
      setActiveTickets(response.data.activeTicketCount);
    } catch (error) {
      globalErrorHandler(error)
    }
  }

  useEffect(() => {
    getActiveTicketCounts()
  }, [])

  return (
    <div className='individual-support-page'>
      <div className='container'>
        {
          availableServices && availableServices?.length !== 0 ? <ServiceCards
            activeTickets={activeTickets}
            handleButtons={handleNavigation}
            serviceType={availableServices}
            isLandingPage={true} /> :
            <div className='no-services-info'>
              <h1 className='subtitle'>This resource isn’t available through your organization.</h1>
              <span className='title'>For assistance, please contact your administrator at </span>
              <span className='title'>{EnvironmentConfig.supportEmail}.</span>
              <br /><span className='title'>If you need immediate help,</span>
              <span className='title'>call our emergency hotline at 800-833-8707</span>
            </div>
        }
      </div>
    </div>
  )
}

export default IndividualSupport