import React from 'react'
import './LessonModule.scss'
import '../JournalEntry/JournalEntry.scss'
import { ApiLesson } from '../../../interfaces/ApiTypes'
import { ExerciseTypes, LessonTypes } from '../../../utils/member-portal/constant'
import VideoModule from './VideoViewer'
import SingleJournalEntry from '../JournalEntry/SingleJournalEntry'
import TitleDescription from '../TitleDescription/TitleDescription'
import JournalList from '../JournalEntry/JournalList'
import { CircularProgress } from '@mui/material'
import ChartedJournal from '../ChartedJournal/ChartedJournal'
import QuestionnaireQues from '../Questionnaire/QuestionnaireQues'

interface LessonProps {
    moduleId: number;
    currentLessonDetails: ApiLesson;
    onLessonUpdated: (moduleId: number, lessonId: number) => void;
    isLessonFetched: boolean;
    handleNextButton: (id: number, flag: boolean) => void;
}

const LessonModule: React.FC<LessonProps> = ({ currentLessonDetails, handleNextButton, onLessonUpdated, isLessonFetched, moduleId }) => {

    return (
        <div className='lesson-module-component'>
            {isLessonFetched ?
                <div className='loader-box'><CircularProgress /></div> :
                <>
                    {
                        currentLessonDetails?.lessonType === LessonTypes.Video && (
                            <VideoModule
                                title={currentLessonDetails.title}
                                description={currentLessonDetails.description}
                                url={currentLessonDetails.muxVideoPlayId}
                                onNextButtonClick={() => handleNextButton(currentLessonDetails.id, true)} />)
                    }
                    {
                        currentLessonDetails?.lessonType === LessonTypes.Exercise && (
                            <>
                                {
                                    currentLessonDetails.exerciseType === ExerciseTypes.scoredQuestionnaire ? null :
                                        <TitleDescription
                                            title={currentLessonDetails.title}
                                            description={currentLessonDetails.description}
                                            onNextButtonClick={() => handleNextButton(currentLessonDetails.id, true)} />
                                }
                                {
                                    currentLessonDetails.exerciseType === ExerciseTypes.multipleJournalEntry &&
                                    <JournalList
                                        moduleId={moduleId}
                                        onLessonUpdated={onLessonUpdated}
                                        lessonId={currentLessonDetails.id}
                                        questions={currentLessonDetails.questions}
                                        entries={currentLessonDetails.submittedEntries} />
                                }
                                {
                                    currentLessonDetails.exerciseType === ExerciseTypes.singleJournalEntry &&
                                    <SingleJournalEntry
                                        moduleId={moduleId}
                                        onLessonUpdated={onLessonUpdated}
                                        lessonId={currentLessonDetails.id}
                                        questions={currentLessonDetails.questions}
                                        entries={currentLessonDetails.submittedEntries} />
                                }
                                {
                                    currentLessonDetails.exerciseType === ExerciseTypes.chartedJournalEntry &&
                                    <ChartedJournal
                                        moduleId={moduleId}
                                        onLessonUpdated={onLessonUpdated}
                                        lessonId={currentLessonDetails.id}
                                        questions={currentLessonDetails.questions}
                                        entries={currentLessonDetails.submittedEntries}
                                        exerciseType={currentLessonDetails.exerciseType} />
                                }
                                {
                                    currentLessonDetails.exerciseType === ExerciseTypes.scoredQuestionnaire &&
                                    <QuestionnaireQues
                                        question={currentLessonDetails.questions}
                                        id={currentLessonDetails.id}
                                        isCompleted={currentLessonDetails.isCompleted}
                                        title={currentLessonDetails.title}
                                        description={currentLessonDetails.description}
                                        onNextButtonClick={() => handleNextButton(currentLessonDetails.id, true)}
                                    />
                                }
                            </>
                        )
                    }
                </>
            }
        </div >
    )
}

export default React.memo(LessonModule)