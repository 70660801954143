import React from 'react'
import './ExploreTopicCard.scss'
import SlackIcon from '../../assests/icons/slack.png'
import BookIcon from '../../assests/icons/book.png'
import { Link } from 'react-router-dom'
import useGlobalState from '../../../context/useGlobalState'

interface CardProps {
  id: number;
  image: string;
  title: string;
  digitalContCount: number | null;
  exerciseCount: number | null;
}

const ExploreTopicCard: React.FC<CardProps> = ({ image, title, digitalContCount, exerciseCount, id }) => {
  return (
    <Link to={`/app/topic/${id}`} className='no-underline'>
      <div className='explore-topic-card-component'>
        <img src={image} alt="card-img" className='card-img' />
        <div className='card-content'>
          <span className='card-title'>{title}</span>
          <div className='card-details'>
            <div className='card-deails-item'>
              <img src={SlackIcon} alt='icon' className='card-deails-icon' />
              <span>{exerciseCount} Exercises</span>
            </div>
          </div>
          <div className='card-details'>
            <div className='card-deails-item'>
              <img src={BookIcon} alt='icon' className='card-deails-icon' />
              <span>{digitalContCount} Digital Content </span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ExploreTopicCard