import { selfScheduleQuestion } from "../../../utils/constants";
import Questions from "../../common/SelfScheduleQuestion/SelfScheduleQuestion";
import React, { useEffect } from "react";
import { AnswersObj } from "../../../views/support-questionnaire/SupportQuestionnaire";

interface Props {
  onNext: (answers: object) => void;
  answers: AnswersObj
}

const RadioButtonAssessmentPage1: React.FC<Props> = ({ onNext, answers }) => {
  useEffect(() => {
    window.scroll(0, 0);
  });

  const option = [
    selfScheduleQuestion.Options.OPTION1,
    selfScheduleQuestion.Options.OPTION2,
    selfScheduleQuestion.Options.OPTION3,
    selfScheduleQuestion.Options.OPTION4,
  ];
  const questions = [
    { question: selfScheduleQuestion.PageOne.QUESTION_1, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_2, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_3, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_4, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_5, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_6, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_7, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_8, options: option },
    { question: selfScheduleQuestion.PageOne.QUESTION_9, options: option },
  ];
  const additionalQuestion = selfScheduleQuestion.Additonal_Question.QUESTION1;
  const additionalOptions = selfScheduleQuestion.Additonal_Question.Options;
  return (
    <div>
      <Questions
        option={option}
        questions={questions}
        additionalQuestion={additionalQuestion}
        additionalOptions={additionalOptions}
        onNext={onNext}
        answers={answers}
      />
    </div>
  );
};

export default RadioButtonAssessmentPage1;
