import React, { useEffect, useState } from "react";
import { Line } from "rc-progress";
import "./ProgressBar.scss";

interface ProgressBarProps {
  progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const [percentage, setPercentage] = useState<number>(0);

  useEffect(() => {
    setPercentage(progress);
  }, [progress]);

  return (
    <Line
      percent={percentage}
      strokeWidth={1}
      strokeColor="#2B7272"
      trailColor="#CFF4F7"
      className="progressbar-component"
    />
  );
};

export default ProgressBar;
