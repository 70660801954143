import React, { useState } from 'react'
import { CCADatePicker } from '../CCADatePicker/CCADatePicker'
import { ScheduleData } from '../../../interfaces/ApiTypes';
import { Select, MenuItem } from '@mui/material';
import { ExerciseTypes } from '../../../utils/member-portal/constant';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import '../../common/Input/DateAndTimeInput.scss'
import BinIcon from '../../assests/icons/green-bin.png'

interface Props {
    id: number;
    title: string;
    required: boolean;
    hideButton: boolean;
    hideTitle?: boolean;
    value: { date: Date | null, time: null | string};
    handleInputChange: (value1: number, value2: { date: Date | null, time: null | string } | string, isRequired: boolean, question: string) => void;
    exerciseType?:string; 
    scheduleData?:ScheduleData | null | undefined;
}

const DateAndTimeSlot:React.FC<Props> = ({ title, hideButton, id, value, handleInputChange, hideTitle, required, exerciseType, scheduleData }) => {
    const [newDate, setNewDate] = useState<Date | null>(value.date);    
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>('');
    const handleDateChange = (newDate: Date | null) => {
         setNewDate(newDate);
        if (exerciseType === ExerciseTypes.chartedJournalEntry) {
            handleInputChange(id, { date: newDate, time: selectedTimeSlot }, required, title);
        } 
    };

    const handleTimeChange = (time: string | null) => {
        if (typeof time === 'string') {
            setSelectedTimeSlot(time);
            handleInputChange(id, { date: newDate, time }, required, title);
        }
    };
    
return (
    <div className='date-and-time-component'>
            <div className='title-btn-bar'>
                {!hideTitle && <span className='input-title'>{title}</span>}
                {
                    !hideButton && <img src={BinIcon} alt='bin-icon' className='green-bin-icon' />
                }
            </div>
            <div className='input-grid'>
                <CCADatePicker
                    placeholder='Date'
                    value={newDate || new Date()}
                    onChange={handleDateChange}
                    exerciseType={exerciseType} />
                    {exerciseType === ExerciseTypes.chartedJournalEntry && (
                     <div className="select-container">
                        <AccessTimeOutlinedIcon className="clock-icon" />
                         <Select
                            value={selectedTimeSlot || 'Select a time slot'}
                            onChange={(e) => handleTimeChange(e.target.value)}
                            IconComponent={() => null}
                            MenuProps={{
                              disableScrollLock: true,
                            }}
                            className='custom-select'
                          >
                            <MenuItem value="Select a time slot"  defaultValue="Select a time slot" disabled>
                                Select a time slot
                            </MenuItem>
                            {scheduleData?.answers?.map((slot, index) => (
                                <MenuItem key={index} value={slot.timeSlot}>
                                    {slot.timeSlot}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                  )}
            </div>
        </div>
  )
}

export default DateAndTimeSlot
