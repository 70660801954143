import React from 'react'
import './ModuleCard.scss'
import './LongCradCommonstyles.scss'
import ContentDescriptionCard from './ContentDescriptionCard'

interface CardProps {
    id: number;
    title: string;
    image: string;
    isStarted: boolean;
    content: string;
    drDetails: string;
    resume?: boolean;
    exercise: number;
    videos: number;
    totalMins: number;
    remainingMins: number | null;
    status: string;
    startButtonClick: () => void
}

const ModuleCard: React.FC<CardProps> = (props) => {
    return (
        <div className='module-card-component'>
            <img className='left-side-img' src={props.image} alt='module-img' />
            <ContentDescriptionCard
                id={props.id}
                path={`/app/module/${props.id}`}
                title={props.title}
                isStarted={props.isStarted}
                content={props.content}
                drDetails={props.drDetails}
                exercise={props.exercise}
                videos={props.videos}
                status={props.status}
                totalMins={props.totalMins}
                remainingMins={props.remainingMins}
                startButtonClick={props.startButtonClick} />
        </div>
    )
}

export default ModuleCard