import { useParams } from 'react-router-dom'
import './Article.scss'
import { useEffect, useRef, useState } from 'react';
import { ApiArticle } from '../../interfaces/ApiTypes';
import { getArticleDetail } from '../../utils/ApiClient';
import BackToModule from '../../component/common/BackToModule/BackToModule';
import Loader from '../../component/common/Loader/Loader';
import EnvironmentConfig from '../../component/config/EnvironmentConfig';
import useGlobalState from '../../context/useGlobalState';
import axios from 'axios';
import useHandleUrlIdError from '../../utils/handleUrlIdError';

const Article = () => {
    const { topicId, articleId } = useParams<{ topicId: string; articleId: string }>();
    const [articleDetail, setArticleDetail] = useState<ApiArticle>()
    const [loading, setLoading] = useState<boolean>(true)
    const { globalErrorHandler } = useGlobalState()
    const handleUrlIdError = useHandleUrlIdError();
    const effectRef = useRef(false)

    useEffect(() => {
        const fetchArticleDetail = async (): Promise<void> => {
            try {
                const response = await getArticleDetail(Number(articleId))
                setArticleDetail(response.data)
                setLoading(false)
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    handleUrlIdError(error);
                } else {
                    globalErrorHandler(error)
                }
                setLoading(false)
            }
        }
        if (effectRef.current === false) {
            fetchArticleDetail()
        }
        return () => { effectRef.current = true }
    }, [articleId])

    return (
        <>
            {
                loading ? <Loader /> :
                    <>
                        {
                            articleDetail && <div className='article-page container'>
                                <BackToModule name='Back to topic' path={`/app/topic/${topicId}`} />
                                <>
                                    <h4 className='media-title'>{articleDetail.title}</h4>
                                    <div className='content-columns' >
                                        <div className='body-section'>
                                            <img alt="contentDetails-photo" src={articleDetail?.photo ? articleDetail?.photo : `${EnvironmentConfig.imageBaseURL}cca_default_image.png`}></img>
                                            {articleDetail?.body && (
                                                <div dangerouslySetInnerHTML={{ __html: articleDetail?.body }}></div>
                                            )}
                                        </div>
                                        <div className='side-details'>
                                            {
                                                (articleDetail?.resources === null || articleDetail?.resources === "") ? "" : <div className='src-section'>
                                                    <span className='src-title'>Need More Info?</span>
                                                    <span className='src-desc'>For more information on this topic, visit these resources: </span>
                                                    <div className='src-links-list'>
                                                        {
                                                            articleDetail?.resources && <div className='resource-section'>
                                                                <span className='src-title'>Resources</span>
                                                                <div className='src-links-list'>
                                                                    {articleDetail?.resources && (
                                                                        <div dangerouslySetInnerHTML={{ __html: articleDetail?.resources }}></div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </>
                            </div>
                        }
                    </>
            }
        </>
    )
}

export default Article