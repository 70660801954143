import { Routes, Route } from "react-router-dom";
import { userType } from "../utils/constants";
import AuthenticatedRoute from "./AuthenticatedRoute";
import VerifyMagicLink from "../component/pages/magic-token-verification/MagicTokenVerification";
import MemberPortalHome from "../views/home/index";
import ExploreTopics from "../views/expore-topics/index";
import Topic from "../views/topic/index";
import IndividualSupport from "../views/individual-support/index";
import Article from "../views/article/index";
import Lesson from "../views/lesson/index";
import Module from "../views/module/index";
import RequestOptions from "../component/pages/work-life/request-options/index";
import RequestForm from "../component/pages/work-life/request-form/index";
import SuccessMessage from "../component/pages/work-life/success-message/index";
import WorkLifeCategories from "../component/pages/work-life/worklife-categories/index";
import AccountInfo from "../component/pages/account-info/index";
import UserInformation from "../component/pages/user-information/index";
import ChatPage from "../component/pages/chat/index";
import WorkLifeForm from "../component/pages/work-life/work-life-form/index";
import CallPage from "../component/pages/call-page/index";
import InvalidLink from "../component/pages/invalid-link/index";
import Test from "../component/pages/test/index";
import Error from "../component/pages/error/index";
import PageNotFound from "../component/pages/page-not-found/index";
import PrivacyPolicy from "../component/pages/privacy-policy/index";
import ServicesCheck from "../component/pages/service-check/index";
import TermsOfService from "../component/pages/terms-of-service";
import SplashScreen from "../views/splash-screen/index";
import SignUp from "../views/sign-up/index";
import Login from "../views/login/index";
import FAQs from "../views/faq/index";
import ContactUs from "../views/contact-us/index";
import SupportQuestionnaire from "../views/support-questionnaire/index";

const AppRouter = () => {
  return (
    <Routes>
      {/* New Routes */}
      <Route path="/" element={<SplashScreen />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/verify/:user_id/:magic_token"
        element={<VerifyMagicLink />}
      />

      <Route
        path="/app/*"
        element={<AuthenticatedRoute allowedType={userType.CUSTOMER} />}
      >
        <Route path="home" element={<MemberPortalHome />} />
        <Route path="explore-topics" element={<ExploreTopics />} />
        <Route path="topic/:topicId" element={<Topic />} />
        <Route path="module/:moduleId" element={<Module />} />
        <Route path="module/:moduleId/lesson/:lessonId" element={<Lesson />} />
        <Route path="topic/:topicId/article/:articleId" element={<Article />} />
        <Route path="individual-support" element={<IndividualSupport />} />

        <Route path="support-questionnaire" element={<SupportQuestionnaire />} />

        {/* TEXT-COACHING & SELF-SCHEDULING-FLOW COMMON ROUTES */}
        <Route path="account-info" element={<AccountInfo />} />
        <Route path="user-info" element={<UserInformation />} />

        {/* TEXT-COACHING-FLOW ROUTES */}
        <Route path="chat/:ticketId" element={<ChatPage />} />

        {/* WORK-LIFE-REQUEST-FLOW ROUTES  */}
        <Route path="worklife-categories" element={<WorkLifeCategories />} />
        <Route path="worklife-form" element={<WorkLifeForm />} />
        <Route path="category/:categoryId" element={<RequestOptions />} />
        <Route path="request-form" element={<RequestForm />} />
        <Route path="success-message" element={<SuccessMessage />} />
        <Route path="404" element={<PageNotFound />} />
        <Route path="*" element={<PageNotFound />} />

      </Route>

      <Route path="/faqs" element={<FAQs />} />
      <Route path="/contact-us" element={<ContactUs />} />

      {/* GENERAL ROUTES */}
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/call-page" element={<CallPage />} />
      <Route path="/invalid-link" element={<InvalidLink />} />
      <Route path="/system-check" element={<AuthenticatedRoute allowedType={userType.CUSTOMER} children={<ServicesCheck />} />} />
      <Route path="/test" element={<Test />} />
      <Route path="/error" element={<Error />} />
      <Route path="/404" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />

    </Routes>
  );
};

export default AppRouter;
