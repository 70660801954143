import { useState, useEffect } from 'react'
import SmallGreenButton from '../Button/SmallGreenButton'
import ExerciseInput from '../Input/ExerciseInput'
import './SingleJournalEntry.scss'
import { ApiQuestions, ApiSubmittedEntries } from '../../../interfaces/ApiTypes';
import { AnswerType } from './JournalEntry';
import { submitExerciseAnswers } from '../../../utils/ApiClient';
import { validateAnswers } from '../../../utils/helperFunctions';
import SuccessMessage from '../SuccessMessage/SuccessMessage';
import useGlobalState from '../../../context/useGlobalState';
import LoadingButton from '../Button/LoadingButton';

interface SingleEntryProps {
    moduleId: number;
    entries: ApiSubmittedEntries[];
    lessonId: number,
    questions: ApiQuestions[];
    onLessonUpdated: (moduleId: number, lessonId: number) => void;
}

const SingleJournalEntry: React.FC<SingleEntryProps> = ({ lessonId, questions, entries, onLessonUpdated, moduleId }) => {
    const [entry, setEntries] = useState(entries);
    const [answers, setAnswers] = useState<AnswerType[]>([]);
    const [questionList, setQuestionList] = useState<ApiQuestions[]>([])
    const { globalErrorHandler } = useGlobalState()
    const [error, setError] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const [successMessage, setSuccessMessage] = useState<string | null>("")
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (entries.length === 0) {
            setAnswers(questions.map((value) => ({
                question_id: value.id,
                answer: "",
                isRequired: value.isRequired,
                question: value.question
            })));
        } else {
            setEntries(entries);
            setAnswers(entries.map(value => ({ question_id: value.id, answer: "", isRequired: value.isRequired, question: value.question })));
        }
    }, [entries, questions]);

    useEffect(() => {
        setQuestionList(questions)
    }, [questions])

    const handleSaveClick = async (): Promise<void> => {
        const hasError = validateAnswers(answers)
        if (hasError) {
            setError(true)
            setErrorMessage(hasError)
        } else {
            setError(false)
            setLoading(true)
            try {
                const cleanedData = answers.map(({ question, isRequired, ...rest }) => rest);
                const response = await submitExerciseAnswers(lessonId, cleanedData)
                setTimeout(() => {
                    setLoading(false)
                    setSuccessMessage(response.message)
                    onLessonUpdated(moduleId, lessonId)
                }, 3000)
            } catch (error) {
                globalErrorHandler(error)
            }
        }
    };

    const handleInputChange = (id: number, value: string | null | number | { date: null; time: null; }, isRequired: boolean, question: string) => {
        setAnswers((prevAnswers) => {
            const existingAnswerIndex = prevAnswers.findIndex((answer) => answer.question_id === id);
            if (existingAnswerIndex !== -1) {
                return prevAnswers.map((answer, index) =>
                    index === existingAnswerIndex ? { ...answer, answer: value } : answer
                );
            }
            return [...prevAnswers, { question_id: id, answer: value, isRequired: isRequired, question: question }];
        });
    };

    return (
        <div className='single-journal-entry-component'>
            {
                entry.length ? <> {
                    entry.map((que: ApiSubmittedEntries, index) => {
                        const entryList = que.submittedAnswers
                        return <div key={"entry" + index}>
                            {
                                entryList?.map((item, index) => {
                                    return <div className='journal-ques-ans' key={"answer" + index}>
                                        <span className='journal-title'>{item.question}</span>
                                        <span className='journal-ans'>{item.answers?.answer}</span>
                                    </div>
                                })
                            }
                        </div>
                    })
                }</> : <>
                    {
                        questionList.map((item, index) => {
                            return <ExerciseInput
                                key={"question" + index}
                                handleInputChange={handleInputChange}
                                name={item.question}
                                required={item.isRequired}
                                id={item.id}
                                value={answers.find((answer) => answer.question_id === item.id)?.answer || ''}
                                title={item.question} />
                        })
                    }
                    {
                        successMessage && <SuccessMessage message={successMessage} />
                    }
                    {
                        error && errorMessage && <span className='error'>{errorMessage}</span>
                    }
                    {
                        loading ? <LoadingButton /> : <SmallGreenButton name='Submit' onClick={handleSaveClick} />
                    }
                </>
            }
        </div>
    )
}

export default SingleJournalEntry