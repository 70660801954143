import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { storageKeys } from "../utils/constants";

interface AuthenticatedRouteProps {
  allowedType: string;
  children?: React.ReactNode; 
}

const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({
  allowedType,
  children,
}) => {
  const userType = localStorage.getItem(storageKeys.UserType);
  const token = localStorage.getItem(storageKeys.Token);

  if (!userType || userType !== allowedType || !token) {
    return <Navigate to="/" />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default AuthenticatedRoute;
