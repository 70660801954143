import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Category,
  Request,
  getRequestObjectsFromCategoryId,
} from "../../../../utils/workLifeData";
import { storageKeys } from "../../../../utils/constants";
import '../../../../styles/globalStyles.scss'
import './RequestOptions.scss'

const RequestOptions: React.FC = () => {
  const { categoryId }: any = useParams<any>();
  const navigate = useNavigate();
  const [requests, setRequests] = useState<any>([]);
  const [categoryName, setCategoryName] = useState("");

  useEffect(() => {
    const response = getRequestObjectsFromCategoryId(categoryId);
    if (response) {
      setCategoryName(response.categoryName);
      setRequests(response.items);
    }
  }, [categoryId]);

  const handleRequest = (request: Category | Request) => {
    if (request.type === "Category") {
      localStorage.setItem(storageKeys.Subcategory, request.name);
      navigate(`/app/category/${request.id}`);
    } else {
      navigate("/app/request-form", { state: request.id });
    }
  };

  return (
    <div className="request-options-page page-container">
      <span className="title">{categoryName}</span>
      <div className="request-grid-container">
        {requests?.map((request: Request | Category, index: number) => {
          return (
            <div key={index} className="request-card-container">
              <div
                className="request-cards"
                onClick={() => handleRequest(request)}
              >
                {request.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RequestOptions;
