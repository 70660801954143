import React, { useState } from 'react'
import { ApiQuestions, ApiSubmittedEntries } from '../../../interfaces/ApiTypes';
import JournalEntry from './JournalEntry';
import './JournalList.scss'

interface JournalListProps {
  moduleId: number;
  lessonId: number;
  entries: ApiSubmittedEntries[];
  questions: ApiQuestions[];
  onLessonUpdated: (moduleId: number, lessonId: number) => void;
}

const JournalList: React.FC<JournalListProps> = ({ moduleId, lessonId, entries, questions, onLessonUpdated }) => {
  const [journalEntries, setJournalEntries] = useState<ApiQuestions[][]>([]);
  const handleAddJournalEntry = () => {
    const newEntry = questions.map((question) => ({
      ...question,
    }));
    setJournalEntries((prevEntries) => [...prevEntries, newEntry]);
  };
  
  return (
    <div className='journal-list-component'>
      {
        entries
          .slice() 
          .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()) 
          .map(({ id, submittedAnswers }, index) => (
            <JournalEntry
              key={`entry-${id}`}
              moduleId={moduleId}
              onLessonUpdated={onLessonUpdated}
              lessonId={lessonId}
              entryId={id}
              questions={submittedAnswers}
              isNewEntry={false}
              setJournalEntries={setJournalEntries}
              entryNo={index}
            />
          ))
      }

      {journalEntries.map((entry, index) => (
        <JournalEntry
          moduleId={moduleId}
          onLessonUpdated={onLessonUpdated}
          isNewEntry={true}
          entryNo={index}
          lessonId={lessonId}
          questions={Array.isArray(entry) ? entry : [entry]}
          key={`entry-${index}`}
          setJournalEntries={setJournalEntries}
        />
      ))}
      <button className='add-button' onClick={handleAddJournalEntry}>+ Add a new entry</button>
    </div>
  )
}

export default JournalList