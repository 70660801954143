import "./CCADatePicker.scss";
import {FC} from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ExerciseTypes } from "../../../utils/member-portal/constant";
import { isDateWithinCurrentWeek } from "../../../utils/helperFunctions";

interface Props {
    value: Date | null;
    placeholder: string;
    onChange: (date: Date | null, name?: string) => void;
    onClick?: () => void;
    maxDate?: Date;
    name?: string;
    exerciseType?:string;
}

export const CCADatePicker: FC<Props> = (props) => {
    const handleDateChange = (date: Date | null) => {
        props.onChange(date, props.name);
    };

    return(
        <div className="cca-date-picker-component">
            <DatePicker 
                onChange={handleDateChange} 
                value={props.value}
                onOpen={props.onClick}
                format="MM/dd/yyyy"
                label={props.placeholder}
                closeOnSelect={true}
                name={props.name}
                shouldDisableDate={(date) => props.exerciseType === ExerciseTypes.chartedJournalEntry && !isDateWithinCurrentWeek(date)}
            />
        </div>
    );
}
