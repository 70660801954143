import { common, meetCoach, storageKeys } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import linkedIn from "../../assests/icons/linkedIn.png";
import "./MeetCoach.scss";
import EnvironmentConfig from "../../config/EnvironmentConfig";

const MeetYourCoach = () => {
  const navigate = useNavigate();
  const handleNavigation = (): void => {
    const ticketId = localStorage.getItem(storageKeys.TicketID);
    navigate(`/app/chat/${ticketId}`);
  };
  return (
    <div className="meet-coach-page page-content">
      <span className="subtitle">{meetCoach.SUBTITLE}</span>
      <span className="coach-title">{meetCoach.TITLE}</span>
      <div className="button-box">
        <button
          className="green-rounded-btn questionbtn_response"
          onClick={handleNavigation}
        >
          {common.NEXT}
        </button>
      </div>
      <div className="coach-intro">
        <div className="coach-avatar">
          <img src={`${EnvironmentConfig.imageBaseURL}coach-avatar.png`} alt="coach" className="coach-image" />
        </div>
        <div className="coach-info">
          <div className="coach-info-header">
            <span className="coach-name">{meetCoach.COACH_NAME}</span>
            <span className="coach-designation">{meetCoach.DESIGNATION}</span>
          </div>
          <span className="details">{meetCoach.DESCRIPTION}</span>
          <img src={linkedIn} alt="linkedIn" className="linkedIn-logo" />
        </div>
      </div>
      <span className="service-response">{meetCoach.RESPONSE}</span>
      <div className="button-box">
        <button
          className={"green-rounded-btn questionbtn_response_desktop"}
          onClick={handleNavigation}
        >
          {common.NEXT}
        </button>
      </div>
    </div>
  );
};

export default MeetYourCoach;
