import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { userVerification } from "../../../utils/ApiClient";
import CredentialStorage from "../../../utils/CredentialStorage";
import logo from "../../assests/icons/logo-green.png";
import Loader from "../../common/Loader/Loader";
import { storageKeys } from "../../../utils/constants";
import "./MagicTokenVarification.scss";

interface VerificationStatus {
  success: boolean;
  message: string;
}

const VerifyMagicLink: React.FC = () => {
  const { user_id, magic_token } = useParams<{
    user_id: string;
    magic_token: string;
  }>();
  const [verificationStatus, setVerificationStatus] =
    useState<VerificationStatus>({
      success: false,
      message: "",
    });
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const effectRef = useRef(false);
  const location = useLocation();
  const searchParam = new URLSearchParams(location.search);
  const redirectURL: any = searchParam.get("redirect_url");

  const verifyUser = async (
    user_id: string,
    magic_token: string
  ): Promise<void> => {
    try {
      const response = await userVerification(user_id, magic_token);
      setVerificationStatus({
        success: true,
        message: response.message,
      });
      CredentialStorage.login(response.data);
      setLoading(false);
      if (redirectURL) {
        navigate(redirectURL);
      } else {
        navigate("/app/home");
      }
    } catch (error: any) {
      setVerificationStatus({
        success: false,
        message: error.response?.data.message,
      });
      setLoading(false);
    }
  };

  const handleHomeNavigation = (): void => {
    const oid: any = localStorage.getItem(storageKeys.OrganizationID);
    const queryString = new URLSearchParams({
      oid: oid,
    }).toString();
    const newUrl = oid ? `/?${queryString}` : "/";
    const token = localStorage.getItem(storageKeys.Token);
    if (token) {
      navigate("/");
    } else {
      navigate(newUrl);
    }
  };

  useEffect(() => {
    if (effectRef.current === false) {
      if (user_id && magic_token) {
        verifyUser(user_id, magic_token);
      }
    }
    return () => {
      effectRef.current = true;
    };
  }, [user_id, magic_token]);

  return (
    <div className="magic-token-varification-page">
      <div className="fixed-header-layout">
        <header className="fixed-header">
          <img
            src={logo}
            alt="logo"
            className="fixed-header-logo"
            onClick={handleHomeNavigation}
          />
        </header>
        <div className="content">
          <div className="page-content">
            {loading ? (
              <Loader />
            ) : (
              <div>
                {verificationStatus.success ? (
                  <div className="success">{verificationStatus.message}</div>
                ) : (
                  <div className="error">{verificationStatus.message}</div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyMagicLink;
