import React, { useEffect, useState } from "react";
import "./FixedHeaderUserLayout.css";
import RedirectCheck from "../../routes/RedirectCheck";
import { useNavigate, useLocation } from "react-router-dom";
import Toast from "../common/Toast/Toast";
import { storageKeys } from "../../utils/constants";
import Footer from "../common/Footer/Footer";
import MemberPortalHeader from "./header/MemberPortalHeader";

interface Props {
  children: React.ReactNode;
  hideMyAccButton?: boolean;
}

const FixedHeaderUserLayout: React.FC<Props> = ({
  children,
}) => {
  const [showFooter, setShowFooter] = useState<boolean>(true);
  const [selectedNavMenu, setSelectedNavMenu] = useState<string | null>(null)
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;
  const isNewVersion = true;

  const userTypeName = localStorage.getItem(storageKeys.UserType)

  const navigateToHomePage = () => {
    setSelectedNavMenu(null)
    navigate("/")
  }

  useEffect(() => {
    if (currentPath.startsWith("/app/chat/") || currentPath === "/" || isNewVersion) {
      setShowFooter(false);
    }
  }, [currentPath, isNewVersion, userTypeName]);

  return (
    <div className="fixed-header-component fixed-header-layout">
      <RedirectCheck />
      <div className="header">
        <MemberPortalHeader
          handleHomeNavigation={navigateToHomePage}
          selectedNavMenu={selectedNavMenu}
          setSelectedNavMenu={setSelectedNavMenu}
          currentPath={currentPath} /></div>
      <Toast />
      <div className="content">{children}</div>
      {showFooter && !isNewVersion && <div className="footer-div"><Footer /></div>}
    </div>
  );
};

export default FixedHeaderUserLayout;
