import { common, storageKeys } from "./constants";
import { LoginResponse } from "../interfaces/ApiTypes"

export interface UserInfoFormData {
  company: string;
  member_type: string;
  gender: string;
  marital_status: string;
  phone: string;
  leave_mail: string;
  street_address: string;
  apt_floor: string;
  city: string;
  state: string;
  zipcode: string;
  dob: string | null;
  age: number
}

interface TicketResponse {
  data: {
    id: number;
    channelName: string;
    channelUrl: string;
  };
}

class CredentialStorage {

  // Storing login credential after successful login/registration
  login(response: LoginResponse): void {
    localStorage.setItem(storageKeys.Token, response.token);
    localStorage.setItem(storageKeys.UserID, response.username);
    localStorage.setItem(storageKeys.Email, response.email);
    localStorage.setItem(storageKeys.FirstName, response.firstName);
    localStorage.setItem(storageKeys.LastName, response.lastName);
    localStorage.setItem(storageKeys.SessionToken, response.sessionToken);
    localStorage.setItem(storageKeys.Company, response.organizationName);
    localStorage.setItem(storageKeys.OrganizationID, response.organizationId);
    localStorage.setItem(
      storageKeys.SessionExpiry,
      response.sessionTokenExpirationTime
    );
    localStorage.setItem(storageKeys.UserType, response.userType);
    localStorage.setItem(storageKeys.ID, response.id);
  }

  // Storing ticket info and channel info after create-ticket
  ticketInfo(response: TicketResponse): void {
    const data = response.data;
    const ticketId = data.id.toString();
    localStorage.setItem(storageKeys.TicketID, ticketId);
    localStorage.setItem(storageKeys.ChannelName, data.channelName);
    localStorage.setItem(storageKeys.ChannelURL, data.channelUrl);
  }

  eapxValues(formData: UserInfoFormData) {
    const phoneWithoutDashes = formData.phone.replace(/-/g, "");
    let flow = localStorage.getItem(storageKeys.Flow);
    let email: any = localStorage.getItem(storageKeys.Email);
    let firstName: any = localStorage.getItem(storageKeys.FirstName);
    let lastName: any = localStorage.getItem(storageKeys.LastName);
    let question_1: any =
      flow === common.SELF_SCHEDULING
        ? localStorage.getItem(storageKeys.SelfScheduleQuestion)
        : localStorage.getItem(storageKeys.TextCoachingQuestion);

    return {
      company: formData.company,
      email: email,
      first_name: firstName,
      last_name: lastName,
      dob: formData.dob,
      age: formData.age,
      gender: formData.gender,
      phone_number: phoneWithoutDashes,
      street_address: formData.street_address,
      state: formData.state,
      zip_code: formData.zipcode,
      marital_status: formData.marital_status,
      client_type: formData.member_type,
      question_1: question_1 ? question_1 : "No value",
      leave_voicemail: formData.leave_mail,
      city: formData.city,
    };
  }
}

const instance = new CredentialStorage();

export default instance;
