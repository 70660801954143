import { AxiosError } from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { clearLocalStorageExceptOID } from './clearStorageData';
import { storageKeys } from './constants';
import useGlobalState from '../context/useGlobalState';

interface ErrorResponseData {
    message?: string;
    [key: string]: any;
}

const useHandleUrlIdError = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathName = location.pathname;
    const { setGlobalError } = useGlobalState()

    const handleUrlIdError = (error: AxiosError): void => {
        if (error.response) {
            const { status, data } = error.response as { status: number; data: ErrorResponseData };
            switch (status) {
                case 400:
                case 404:
                    navigate('/404');
                    break;
                case 401:
                    clearLocalStorageExceptOID(storageKeys.OrganizationID);
                    navigate(`/login?redirect_url=${encodeURIComponent(pathName)}`);
                    break;
                default:
                    setGlobalError(data?.message || 'An unexpected error occurred.');
                    break;
            }
        } else {
            setGlobalError('An unexpected error occurred.');
        }
    };


    return handleUrlIdError;
};

export default useHandleUrlIdError;
