import ContentDescriptionCard from '../../component/common/Cards/ContentDescriptionCard'
import './Module.scss'
import ModuleSection from '../../component/common/ModuleSection/ModuleSection'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { getCBTModule, updateModuleProgress } from '../../utils/ApiClient'
import { ApiCBTModule } from '../../interfaces/ApiTypes'
import Loader from '../../component/common/Loader/Loader'
import useGlobalState from '../../context/useGlobalState'
import { ModuleStatus } from '../../utils/member-portal/constant'
import { findFirstIncompleteLesson } from '../../utils/helperFunctions'
import useHandleUrlIdError from '../../utils/handleUrlIdError'
import axios from 'axios'

const Module = () => {
  const { moduleId } = useParams<{ moduleId: string }>();
  const effectRef = useRef(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [moduleDetails, setModuleDetails] = useState<ApiCBTModule | null>(null)
  const handleUrlIdError = useHandleUrlIdError();
  const { globalErrorHandler } = useGlobalState()
  const navigate = useNavigate()

  const fetchCBTModules = async (): Promise<void> => {
    try {
      const response = await getCBTModule(Number(moduleId))
      setModuleDetails(response.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (axios.isAxiosError(error)) {
        handleUrlIdError(error);
      } else {
        globalErrorHandler(error)
      }
    }
  }

  useEffect(() => {
    if (effectRef.current === false) {
      fetchCBTModules()
    }

    return () => { effectRef.current = true }
  }, [moduleId, globalErrorHandler])

  const handleModuleProgress = async (): Promise<void> => {
    try {
      await updateModuleProgress(Number(moduleId) ?? null, ModuleStatus.inProgress)
      if (moduleDetails && moduleDetails.sections) {
        const lessonId = findFirstIncompleteLesson(moduleDetails.sections)
        navigate(`/app/module/${moduleId}/lesson/${lessonId?.lessonId}`)
      }
    } catch (error) {
      globalErrorHandler(error)
    }
  }

  return (
    <>
      {
        loading ? <Loader /> :
          <>{
            moduleDetails && moduleDetails.sections && <div className='module-page'>
              <img src={moduleDetails.thumbnail} alt="module-cover-img" className='module-cover-image' />
              <ContentDescriptionCard
                id={moduleDetails.id}
                disableButton={findFirstIncompleteLesson(moduleDetails.sections)?.lessonId ? false : true}
                path={`/app/module/${moduleDetails.id}/lesson/${findFirstIncompleteLesson(moduleDetails.sections)?.lessonId}`}
                title={moduleDetails.name}
                content={moduleDetails.description}
                isStarted={moduleDetails.isStarted}
                exercise={moduleDetails.totalExercises}
                videos={moduleDetails.totalVideos}
                totalMins={moduleDetails.totalMinutes}
                status={moduleDetails.status}
                remainingMins={moduleDetails.remainingMintues}
                drDetails={moduleDetails.curatorName}
                startButtonClick={handleModuleProgress} />
              <div className='container'>
                <ModuleSection
                  moduleSectionList={moduleDetails.sections}
                  moduleId={moduleDetails.id}
                  isStarted={moduleDetails.isStarted}
                  disableResumeButton={findFirstIncompleteLesson(moduleDetails.sections)?.lessonId ? false : true}
                  startButtonClick={handleModuleProgress} />
              </div>
            </div>
          }</>
      }
    </>
  )
}

export default Module