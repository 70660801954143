import React from 'react'
import './SupportCard.scss'
import { useNavigate } from 'react-router-dom';
import { common, storageKeys } from '../../../utils/constants';
import { Services } from '../../../utils/member-portal/constant';

interface CardProps {
    title: string;
    image: string;
    description: string;
    button: string;
    path: string;
    flow: string;
    service: string;
    activeTickets: number | undefined
}

const SupportCard: React.FC<CardProps> = ({ title, image, description, button, path, flow, service, activeTickets }) => {
    const navigate = useNavigate()
    const inActiveButtons = activeTickets ? activeTickets > 0 : false;
    const handleButtonClick = (path: string, name: string) => {
        if ((flow === Services.textCoaching || flow === Services.getAnswers) && inActiveButtons) {
            return
        }
        if (service === Services.getAnswers) {
            localStorage.setItem(storageKeys.Flow, Services.textCoaching)
            localStorage.setItem(storageKeys.TextCoachingQuestion, common.TEXT_COACHING_OPTION)
        } else {
            localStorage.setItem(storageKeys.Flow, name)
        }
        navigate(path)
    }
    return (
        <div className='support-card-component'>
            <img src={image} className='support-card-img' alt='support-image' />
            <div className='support-card-content'>
                <span className='support-card-title'>{title}</span>
                <span className='support-description'>{description}</span>
                <button
                    className={`support-button ${(flow === Services.textCoaching || flow === Services.getAnswers) && inActiveButtons
                        ? 'inactive-button'
                        : 'active-button'
                        }`}
                    onClick={() => handleButtonClick(path, flow)}
                >
                    {button}
                </button>
            </div>
        </div>
    )
}

export default SupportCard