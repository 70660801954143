import './MemberPortalHeader.scss'
import { useNavigate } from 'react-router-dom'
import React, { useState } from 'react'
import GreenLogo from '../../assests/icons/logo-green.png'
import CloseIcon from '../../assests/icons/close-black.png'
import MenuIcon from '../../assests/icons/menu-black.png'
import ExpandableMenu from '../../../component/common/ExpandableMenu/ExpandableMenu'
import ResponsiveNavbar from '../../../component/layout/navbar/ResponsiveNavbar'
import NavBar from '../navbar/NavBar'
import { storageKeys } from '../../../utils/constants'

interface HeaderProps {
    handleHomeNavigation: () => void;
    currentPath: string;
    selectedNavMenu: string | null;
    setSelectedNavMenu: (menu: string | null) => void;
}

const MemberPortalHeader: React.FC<HeaderProps> = ({ handleHomeNavigation, currentPath, selectedNavMenu, setSelectedNavMenu }) => {
    const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false)
    const [openNavMenu, setOpenNavMenu] = useState<boolean>(false)
    const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false)
    const clientLogo = currentPath === "/" || currentPath === "/sign-up" || currentPath === "/login"
    const hiddenPaths = ["/invalid-link", "/call-page", "/404"];
    const isNavbarHidden = hiddenPaths.includes(currentPath);
    const token = localStorage.getItem(storageKeys.Token)

    const navigate = useNavigate()

    const handleMenuClick = () => {
        setOpenNavMenu(!openNavMenu)
    }

    return (
        <header className='member-portal-header-component'>
            <div className='left-logo-section'>
                <img
                    src={GreenLogo}
                    alt="logo"
                    onClick={handleHomeNavigation}
                    className='green-logo'
                />
                {
                    !clientLogo && <span className='client-logo'>CLIENT LOGO</span>
                }
            </div>
            {
                !token && !isNavbarHidden ? <div className="right-alinged-btns">
                    <span className="info-text" onClick={() => navigate('/login')}>SIGN IN</span>
                    <button className="sign-in-btn" onClick={() => navigate('/sign-up')}>SIGN UP</button>
                </div> : <>
                    {!isNavbarHidden && <>
                        <img src={openNavMenu ? CloseIcon : MenuIcon}
                            alt='menu-icon'
                            className='menu-icon'
                            onClick={handleMenuClick} />
                        <div className='nav-bar-response'>
                            <NavBar
                                isMenuExpanded={isMenuExpanded}
                                setIsMenuExpanded={setIsMenuExpanded}
                                selectedNavMenu={selectedNavMenu}
                                setSelectedNavMenu={setSelectedNavMenu}
                                openProfileMenu={openProfileMenu}
                                setOpenProfileMenu={setOpenProfileMenu} />
                        </div>
                    </>
                    }
                </>
            }
            {openNavMenu && token && <ResponsiveNavbar openNavMenu={openNavMenu} setOpenNavMenu={setOpenNavMenu} openProfileMenu={openProfileMenu} setOpenProfileMenu={setOpenProfileMenu} />}
            {isMenuExpanded && !openNavMenu && token && <ExpandableMenu setIsMenuExpanded={setIsMenuExpanded} />}
        </header>
    )
}

export default MemberPortalHeader