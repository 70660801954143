import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { accountInfo, storageKeys } from "../../../utils/constants";
import {
  ticketList,
  getServiceType,
  getCustomerRefferals,
} from "../../../utils/ApiClient";
import Loader from "../../common/Loader/Loader";
import ServiceCards from "../../common/ServiceComponent/ServiceComponent";
import { useNavigationHandler } from "../../../utils/useNavigationHandler";
import SideTabs from "../../common/SideTabs/SideTabs";
import RefferalList from "../../common/RefferalList/RefferalList";
import ConversationList from "../../ConversationList/ConversationList";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import "./AccountInfo.scss";
import "../../../styles/globalStyles.scss";
import useGlobalState from "../../../context/useGlobalState";
import { ApiTicket } from "../../../interfaces/ApiTypes";

const AccountInfo: React.FC = () => {
  const navigate = useNavigate();
  const { changedFlowNavigation } = useNavigationHandler();
  const [activeTickets, setActiveTickets] = useState<number>(0);
  const [serviceType, setServiceType] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tickets, setTickets] = useState<ApiTicket[]>([]);
  const [refferals, setRefferals] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<string>(accountInfo.CONVERSATIONS);
  const [serviceCompleted, setServiceCompleted] = useState(false);
  const effectRef = useRef(false);
  const { globalErrorHandler, globalError } = useGlobalState()
  const oId: string | null = localStorage.getItem(storageKeys.OrganizationID);
  const tabs = [accountInfo.CONVERSATIONS, accountInfo.REFFERALS];

  const getService = async (): Promise<void> => {
    try {
      const response = await getServiceType(oId ?? "");
      const responseArray = response.data.listOfAvailableService;
      setServiceType(responseArray);
      localStorage.setItem(
        storageKeys.AvailableService,
        JSON.stringify(responseArray)
      );
      setServiceCompleted(true);
    } catch (error) {
      setLoading(false);
      globalErrorHandler(error);
    }
  };

  const getTickets = async (): Promise<void> => {
    setLoading(true)
    try {
      const ticketsResponse = await ticketList();
      setActiveTickets(ticketsResponse.data.activeTicketCount);
      localStorage.setItem(
        storageKeys.ActiveTicket,
        ticketsResponse.data.activeTicketCount.toString()
      );
      setTickets(ticketsResponse.data.tickets);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      globalErrorHandler(error);
    }
  };

  const getRefferals = async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await getCustomerRefferals();
      setRefferals(response.data);
      setLoading(false);
    } catch (error) {
      globalErrorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (effectRef.current === false) {
      getService();
    }
    return () => {
      effectRef.current = true;
    };
  }, []);

  useEffect(() => {
    if (activeTab === accountInfo.REFFERALS) {
      getRefferals();
    } else if (serviceCompleted && activeTab === accountInfo.CONVERSATIONS) {
      getTickets();
    }
  }, [activeTab, serviceCompleted]);

  const handleButton = async (flow?: string) => {
    const flowName = flow || "";
    changedFlowNavigation(flowName);
  };

  const renderNoConversation = useMemo(
    () =>
      !tickets.length &&
      !loading && (
        <span className="no-conversation">{accountInfo.NO_CONVERSATIONS}</span>
      ),
    [tickets.length, loading]
  );

  const handleticket = (ticketId: number) => {
    navigate(`/app/chat/${ticketId}`);
  };

  return (
    <div
      className="account-info-page"
      style={globalError !== null ? { marginTop: "10px" } : {}}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="grid-div">
          {EnvironmentConfig.showCoversationSideTabs && (
            <SideTabs
              tabs={tabs}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          )}
          {activeTab === accountInfo.CONVERSATIONS ? (
            <div className="account-info-content page-container">
              <span className="title">{accountInfo.TITLE}</span>
              {renderNoConversation}
              <ConversationList tickets={tickets} handleticket={handleticket} />
              <div className="service-cards-div">
                <ServiceCards
                  serviceType={serviceType}
                  handleButtons={handleButton}
                  activeTickets={activeTickets}
                  isLandingPage={false}
                />
              </div>
            </div>
          ) : (
            <RefferalList refferals={refferals} title="Refferals" />
          )}
        </div>
      )}
    </div>
  );
};

export default AccountInfo;
