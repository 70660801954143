import { useEffect } from "react";
import "./TermsOfService.scss";

const TermsOfUse = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="terms-of-service-page">
      <h1 className="ql-align-center"><strong>TERMS OF SERVICE</strong></h1>

      <p className="ql-align-justify">The website and services located at and provided through www.ccaplatform.com (the “<strong>Site</strong>”) are copyrighted works belonging to Corporate Counseling Associates, Inc. ("CCA") (“<strong>Company</strong>”, “<strong>us</strong>”, “<strong>our</strong>”, and “<strong>we</strong>”).Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.All such additional terms, guidelines, and rules are incorporated by reference into these Terms.</p>
      <p className="ql-align-justify">THESE TERMS OF SERVICE (THESE “<strong>TERMS</strong>”) SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT GOVERN YOUR USE OF THE SITE.BY ACCESSING OR USING THE SITE, YOU ARE ACCEPTING THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT).YOU MAY NOT ACCESS OR USE THE SITE OR ACCEPT THE TERMS IF YOU ARE NOT AT LEAST 18 YEARS OLD.IF YOU DO NOT AGREE WITH ALL OF THE PROVISIONS OF THESE TERMS, DO NOT ACCESS AND/OR USE THE SITE.</p>
      <p className="ql-align-justify">THESE TERMS REQUIRE THE USE OF ARBITRATION (SECTION 10.2) ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS, AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A DISPUTE.</p>
     
      <p><strong>1. </strong><strong>ACCOUNTS</strong></p>
      <p><strong>1.1 Account Creation.</strong>To use certain features of the Site, you must register for an account (“<strong>Account</strong>”) and provide certain information about yourself as prompted by the account registration form.You represent and warrant that: (a) all required registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information; and (c) you, on your own behalf as an individual agree to these Terms.You may delete your Account at any time, for any reason, by following the instructions on the Site.Company may suspend or terminate your Account in accordance with Section8.</p>
      <p><strong>1.2 Account Responsibilities.</strong>You are responsible for maintaining the confidentiality of your Account login information and are fully responsible for all activities that occur under your Account.You agree to immediately notify Company of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security.Company cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.In the event access to the Site or a portion thereof is limited requiring a user ID and password (“Protected Areas”), you agree to access Protected Areas using only your user ID and password.You agree to protect the confidentiality of your user ID and password, and not to share or disclose your user ID or password to any third party. You agree that you are fully responsible for all activity occurring under your user ID.</p>
      <p><strong>1.3 Consent</strong>.By using the Site,Youagree that CCA may communicate with you, including electronically, about the Site, other business, including marketing materials.</p>
      <p><strong>1.4 Intake &amp; Scheduling.</strong>If you request coaching or counseling support, you will be asked to provide information about yourself so that we can assist you with scheduling an appointment with the appropriate provider.Your communications with the provider will be subject to the terms and conditions of the providers.</p>
      <p><strong>1.5 Coaching Sessions.</strong>In some instances, upon your request, we may provide your information with athird partysite that facilitates texting between you and coaches.Those messages will be subject to the terms and conditions of those third parties.</p>
      <p><strong>2.</strong><strong>ACCESS TO THE SITE</strong></p>
      <p><strong>2.1 License.</strong>Subject to these Terms, Company grants you a non-transferable, non-exclusive, revocable, limited license to use and access the Site solely for your use as authorized by your employer.</p>
      <p><strong>2.2 Certain Restrictions.</strong>The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit the Site, whether in whole or in part, or any content displayed on the Site; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Site; (c) you shall not access the Site in order to build a similar or competitive website, product, or service; (d) except as expressly stated herein, no part of the Site may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means; and (e) if you will be using the Site to administer protected health information (“PHI”) or for services associated with the Health Insurance Portability and Accountability Act of 1996 (“HIPAA”), then you will also comply with the provisions of the HIPAA Addendum.Unless otherwise indicated, any future release, update, or other addition to functionality of the Site shall be subject to these Terms.All copyright and other proprietary notices on the Site (or on any content displayed on the Site) must be retained on all copies thereof.</p>
      <p><strong>2.3 Modification.</strong>Company reserves the right, at any time, to modify, suspend, or discontinue the Site (in whole or in part) with or without notice to you.You agree that Company will not be liable to you or to any third party for any modification, suspension, or discontinuation of the Site or any part thereof.</p>
      <p><strong>2.4 No Support or Maintenance.</strong>You acknowledge and agree that Company will have no obligation to provide you with any support or maintenance in connection with the Site.</p>
      <p><strong>2.5 Ownership.</strong>Excluding any User Content that you may provide (defined below), you acknowledge that all the intellectual property rights, including copyrights, patents,trade marks, and trade secrets, in the Site and its content are owned by Company or Company’s suppliers.Neither these Terms (nor your access to the Site) transfers to you or any third party any rights,titleor interest in or to such intellectual property rights, except for the limited access rights expressly set forth in Section2.1. Company and its suppliers reserve all rights not granted in these Terms.There are no implied licenses granted under these Terms.</p>
      <p><strong>3. USER CONTENT</strong></p>
      <p><strong>3.1 User Content.</strong>“<strong>User Content</strong>” meansany and allinformation and content that a user submits to, or uses with, the Site (e.g., content in the user’s profile or postings), including PHI, if applicable.You are solely responsible for your User Content.You assume all risks associated with use of your User Content, including any reliance on its accuracy, completeness or usefulness by others, or any disclosure of your User Content that personally identifies you or any third party.You hereby represent and warrant that your User Content does not violate our Acceptable Use Policy (defined in Section3.3) and, if applicable, will comply with HIPAA.You may not represent or imply to others that your User Content is in any way provided,sponsoredor endorsed byCompany.Company is not obligated to backup any User Content, and your User Content may be deleted at any time without prior notice.You are solely responsible for creating and maintaining your own backup copies of your User Content if you desire.</p>
      <p><strong>3.2 License.</strong>Subject to the limitations in the HIPAA Addendum related to PHI, You hereby grant (and you represent and warrant that you have the right to grant) to Company an irrevocable, nonexclusive, royalty-free and fully paid, worldwide license to reproduce, distribute, publicly display and perform, prepare derivative works of, incorporate into other works, and otherwise use and exploit your User Content, and to grant sublicenses of the foregoing rights, solely for the purposes of including your User Content in the Site.You hereby irrevocably waive (and agree to cause to be waived) any claims and assertions of moral rights or attribution with respect to your User Content.</p>
      <p><strong>3.3 </strong><strong>Acceptable Use Policy.</strong>The following terms constitute our “<strong>Acceptable Use Policy</strong>”:</p>
      <p><strong>(a)</strong>You agree not to use the Site to collect, upload, transmit, display, or distribute any User Content (i) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (ii) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive, promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual or is otherwise objectionable; (iii) that is harmful to minors in any way; or (iv) that is in violation of any law, regulation, or obligations or restrictions imposed by any third party.</p>
      <p><strong>(b)</strong>In addition, you agree not to: (i) upload, transmit, or distribute to or through the Site any computer viruses, worms, or any software intended to damage or alter a computer system or data; (ii) send through the Site unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (iii)use the Site to harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (iv) interfere with, disrupt, or create an undue burden on servers or networks connected to the Site, or violate the regulations, policies or procedures of such networks; (v) attempt to gain unauthorized access to the Site (or to other computer systems or networks connected to or used together with the Site), whether through password mining or any other means; (vi) harass or interfere with any other user’s use and enjoyment of the Site; or (vi) use software or automated agents or scripts to produce multiple accounts on the Site, or to generate automated searches, requests, or queries to (or to strip, scrape, or mine data from) the Site (provided, however, that we conditionally grant to the operators of public search engines revocable permission to use spiders to copy materials from the Site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials, subject to the parameters set forth in our robots.txt file).</p>
      <p><strong>3.4 Enforcement.</strong>We reserve the right (but have no obligation) to review any User Content, and to investigate and/or take appropriate action against you in our sole discretion if you violate the Acceptable Use Policy or any other provision of these Terms or otherwise create liability for us or any other person.Such action may include removing or modifying your User Content, terminating your Account in accordance with Section8, and/or reporting you to law enforcement authorities.</p>
      <p><strong>3.5 Feedback.</strong>If you provide Company with any feedback or suggestions regarding the Site (“<strong>Feedback</strong>”), you hereby assign to Company all rights in such Feedback and agree that Company shall have the right to use and fully exploit such Feedback and related information in any manner it deems appropriate.Company will treat any Feedback you provide to Company as non-confidential and non-proprietary.You agree that you will not submit to Company any information or ideas that you consider to be confidential or proprietary.</p>
      <p><strong>4. INDEMNIFICATION.</strong>You agree to indemnify and hold Company (and its officers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Site, (b) your violation of these Terms, (c) your violation of applicable laws or regulations or (d) your User Content.Company reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims.You agree not to settle any matter without the prior written consent of Company.Company will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.</p>
      <p><strong>5. </strong><strong>THIRD-PARTY LINKS &amp; ADS; OTHER USERS</strong></p>
      <p><strong>5.1 Third-Party Links &amp; Ads.</strong>The Site may contain links to third-party websites and services, and/or display advertisements for third parties (collectively, “<strong>Third-Party Links &amp; Ads</strong>”).Such Third-Party Links &amp; Ads are not under the control of Company, and Company is not responsible for any Third-Party Links &amp; Ads.Company provides access to these Third-Party Links &amp; Ads only as a convenience to you, and does not review, approve, monitor, endorse, warrant, or make any representations with respect to Third-Party Links &amp; Ads.You use all Third-Party Links &amp; Ads at your ownrisk, andshould apply a suitable level of caution and discretion in doing so. When you click on any of the Third-Party Links &amp; Ads, the applicable third party’s terms and policies apply, including the third party’s privacy and data gathering practices.You should make whatever investigation you feel necessary or appropriate before proceeding with any transaction in connection with such Third-Party Links &amp; Ads.</p>
      <p><strong>5.2 Other Users.</strong>Each Site user is solely responsible forany and allof its own User Content.Because we do not control User Content, you acknowledge and agree that we are not responsible for any User Content, whether provided by you or by others.We make no guarantees regarding the accuracy, currency, suitability, or quality of any User Content.Your interactions with other Site users are solely between you and such users.You agree that Company will not be responsible for any loss or damage incurred as the result of any such interactions.If there is a dispute between you and any Site user, we are under no obligation to become involved.</p>
      <p><strong>5.3 Release.</strong>You hereby release and forever discharge the Company (and our officers, employees, agents, successors, and assigns) from, and hereby waive and relinquish, each and every past, present and future dispute, claim, controversy, demand, right, obligation, liability, action and cause of action of every kind and nature (including personal injuries, death, and property damage), that has arisen or arises directly or indirectly out of, or that relates directly or indirectly to, the Site (including any interactions with, or act or omission of, other Site users or any Third-Party Links &amp; Ads).IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA CIVIL CODE SECTION 1542 IN CONNECTION WITH THE FOREGOING, WHICH STATES: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.”</p>
      <p><strong>6. DISCLAIMERS</strong></p>
      <p><strong>6.1 NO MEDICAL ADVICE.</strong>CCA IS NOT A HEALTH CARE PROVIDER.WE PROVIDE EMPLOYEE ASSISTANCE PROGRAMS PROVIDING INDIVIDUALS WITH ACCESS TO COACHES OR COUNSELORS.ACCORDINGLY, CCA CAN NOT AND DOES NOT DIAGNOSE OR TREAT YOUR HEALTH CONDITIONS.WE PROVIDE NO ENDORSEMENT, REPRESENTATION, OR WARRANTY THAT ANY PARTICULAR TREATMENT IS OR WILL BE EFFECTIVE OR RIGHT FOR YOU.YOU SHOULD ALWAYS SEEK THE ADVICE OF A QUALIFIED MEDICAL PROFESSIONAL REGARDING ANY MEDICAL CONDITION, STARTING A NEW MEDICAL TREATMENT, OR ALTERING AN EXISTING MEDICAL TREATMENT.<strong>IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, DIAL “911” IMMEDATELY.</strong></p>
      <p className="ql-align-justify"></p>
      <p className="ql-align-justify">  <strong>6.2</strong><strong>WARRANTY DISCLAIMER</strong>.THE SITE IS PROVIDED ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND COMPANY (AND OUR SUPPLIERS) EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT.WE (AND OUR SUPPLIERS) MAKE NO WARRANTY THAT THE SITE WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE.IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE SITE, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU.SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.</p>
      
      <p><strong>7. LIMITATION ON LIABILITY</strong></p>
      <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (OR OUR SUPPLIERS) BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE SITE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.ACCESS TO, AND USE OF, THE SITE IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM.</p>
      <p>TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THESE TERMS(FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OFONE HUNDREDUS DOLLARS (U.S. $100). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT.YOU AGREE THAT OUR SUPPLIERS WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THISTERMS.</p>
      <p>SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.</p>
      <p><strong>8. TERM AND TERMINATION.</strong>Subject to this Section, these Terms will remain in full force and effect while you use the Site.We may suspend or terminate your rights to use the Site(including your Account) at any time for any reason at our sole discretion, including for any use of the Site in violation of these Terms.Upon termination of your rights under these Terms, your Account and right to access and use the Site will terminate immediately.You understand that any termination of your Account may involve deletion of your User Content associated with your Account from our live databases.Company will not have any liability whatsoever to you for any termination of your rights under these Terms, including for termination of your Account or deletion of your User Content.Even after your rights under these Terms are terminated, the following provisions of these Terms will remain in effect: Sections2.2through2.5, Section3and Sections 4 through 10.</p>
      <p><strong>9. COPYRIGHT POLICY.</strong></p>
      <p>Company respects the intellectual property of others and asks that users of our Site do the same.In connection with our Site, we have adopted and implemented a policy respecting copyright law that provides for the removal of any infringing materials and for the termination, in appropriate circumstances, of users of our online Site who are repeat infringers of intellectual property rights, including copyrights.If you believe that one of our users is,through the use ofour Site, unlawfully infringing the copyright(s) in a work, and wish to have the allegedly infringing material removed, the following information in the form of a written notification (pursuant to 17 U.S.C. § 512(c)) must be provided to our designated Copyright Agent:</p>
      <ol>
        <li className="ql-align-justify">your physical or electronicsignature;</li>
        <li className="ql-align-justify">identification of the copyrighted work(s) that you claim to have beeninfringed;</li>
        <li className="ql-align-justify">identification of the material on our services that you claim is infringing and that you request us toremove;</li>
        <li className="ql-align-justify">sufficient information to permit us to locate suchmaterial;</li>
        <li className="ql-align-justify">your address, telephone number, and e-mailaddress;</li>
        <li className="ql-align-justify">a statement that you have a good faith belief that use of the objectionable material is not authorized by the copyright owner, its agent, or under the law; and</li>
        <li className="ql-align-justify">a statement that the information in the notification is accurate, and under penalty of perjury, that you are either the owner of the copyright that has allegedly been infringed or that you are authorized to act on behalf of the copyright owner.</li>
      </ol>
      <p className="ql-align-justify"><br /></p>
      <p className="ql-align-justify">Please note that, pursuant to 17 U.S.C. § 512(f), any misrepresentation of material fact (falsities) in a written notification automatically subjects the complaining party to liability for any damages, costs and attorney’s fees incurred by us in connection with the written notification and allegation of copyright infringement.</p>
      <p className="ql-align-justify"></p>
      <p><strong>10. GENERAL</strong></p>
      <p><strong>10.1 Changes.</strong>These Terms are subject to occasional revision, and if we make any substantial changes, we may notify you by sending you an e-mail to the last e-mail address you provided to us (if any), and/or by prominently posting notice of the changes on our Site.You are responsible for providing us with your most current e-mail address.In the event thatthe last e-mail address that you have provided us is not valid, or for any reason is not capable of delivering to you the notice described above, our dispatch of the e-mail containing such notice will nonetheless constitute effective notice of the changes described in the notice.Any changes to these Terms will be effective upon the earlier of thirty (30) calendar days following our dispatch of an e-mail notice to you (if applicable) or thirty (30) calendar days following our posting of notice of the changes on our Site.These changes will be effective immediately for new users of our Site.Continued use of our Site following notice of such changes shall indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.</p>
      <p><strong>10.2 Dispute Resolution.<em>Please read this Arbitration Agreement carefully.It is part of your contract with Company and affects your rights.It contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER.</em></strong></p>
      <p><strong>(a)</strong><em>Applicability of Arbitration Agreement.</em>All claims and disputes (excluding claims for injunctive or other equitable relief as set forth below) in connection with the Terms or the use of any product or service provided by the Company that cannot be resolved informally or in small claims court shall be resolved by binding arbitration on an individual basis under the terms of this Arbitration Agreement.Unless otherwise agreed to, all arbitration proceedings shall be held in English.This Arbitration Agreement applies to you and the Company, and to any subsidiaries, affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as all authorized or unauthorized users or beneficiaries of services or goods provided under the Terms.</p>
      <p><strong>(b)</strong><em>Notice Requirement and Informal Dispute Resolution</em>.Before either party may seek arbitration, the party must first send to the other party a written Notice of Dispute (<strong>“Notice”</strong>) describing the nature and basis of the claim or dispute, and the requested relief.A Notice to the Company should be sent to: Legal Department, 475 Park Avenue South, 8<sup>th</sup>Floor, New York, New York 10016.After the Notice is received, you and the Company may attempt to resolve the claim or dispute informally.If you and the Company do not resolve the claim or dispute within thirty (30) days after the Notice is received, either party may begin an arbitration proceeding.The amount of any settlement offer made by any party may not be disclosed to the arbitrator until after the arbitrator has determined the amount of the award, if any, to which either party is entitled.</p>
      <p><strong>(c)</strong>Arbitration Rules.Arbitration shall be initiated through the American Arbitration Association (<strong>“AAA”</strong>), an established alternative dispute resolution provider (<strong>“ADR Provider”</strong>) that offers arbitration as set forth in this section.If AAA is not available to arbitrate, the parties shall agree to select an alternative ADR Provider.The rules of the ADR Provider shall govern all aspects ofthe arbitration, including but not limited to the method of initiating and/or demanding arbitration, except to the extent such rulesare in conflict withthe Terms.The AAA Commercial Arbitration Rules (<strong>“Arbitration Rules”</strong>) governing the arbitration are available online at<a href="http://www.adr.org/" target="_blank"  rel="noreferrer">www.adr.org</a>or by calling the AAA at 1-800-778-7879.The arbitration shall be conducted by a single, neutral arbitrator.Any claims or disputes where the total amount of the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through binding non-appearance-based arbitration, at the option of the party seeking relief.For claims or disputes where the total amount of the award sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the right to a hearing will be determined by the Arbitration Rules.Any hearing will be held in a location within 100 miles of your residence, unless you reside outside of the United States, and unless the parties agree otherwise.If you reside outside of the U.S., the arbitrator shall give the parties reasonable notice of the date,timeand place of any oral hearings. Any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.Each party shall bear its own costs (including attorney’s fees) and disbursements arising out of the arbitration and shall pay an equal share of the fees and costs of the ADR Provider.</p>
      <p><strong>(d)</strong><em>Additional Rules for Non-Appearance Based Arbitration</em>.Ifnon-appearance basedarbitration is elected, the arbitration shall be conducted by telephone, online and/or based solely on written submissions; the specific manner shall be chosen by the party initiating the arbitration.The arbitration shall not involve any personal appearance by the parties or witnesses unless otherwise agreed by the parties.</p>
      <p><strong>(e)</strong><em>Time Limits.</em>If you or the Company pursue arbitration, the arbitration action must be initiated and/or demanded within the statute of limitations (i.e., the legal deadline for filing a claim) and within any deadline imposed under the AAA Rules for the pertinent claim.</p>
      <p><strong>(f)</strong><em>Authority of Arbitrator</em>.If arbitration is initiated, the arbitrator will decide the rights and liabilities, if any, of you and the Company, and the dispute will not be consolidated with any other matters or joined with any other cases or parties.The arbitrator shall have the authority to grant motions dispositive of all or part of any claim.The arbitrator shall have the authority to award monetary damages, and to grant any non-monetary remedy or relief available to an individual under applicable law, the AAA Rules, and the Terms.The arbitrator shall issue a written award and statement of decision describing the essential findings and conclusions on which the award is based, including the calculation of any damages awarded.The arbitrator has the same authority to award relief on an individual basis that a judge in a court of law would have.The award of the arbitrator is final and binding upon you and the Company.</p>
      <p><strong>(g)</strong><em>Waiver of Jury Trial.</em>THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims and disputes shall be resolved by arbitration under this Arbitration Agreement.Arbitration procedures are typically more limited, moreefficientand less costly than rules applicable in a court and are subject to very limited review by a court.In the event any litigation should arise between you and the Company in any state or federal court in a suit to vacate or enforce an arbitration award or otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL, instead electing that the dispute be resolved by a judge.</p>
      <p><strong>(h)</strong><em>Waiver of Class or Consolidated Actions</em>.ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, AND CLAIMS OF MORE THAN ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR LITIGATED JOINTLY OR CONSOLIDATED WITH THOSE OF ANY OTHER CUSTOMER OR USER.</p>
      <p><strong>(i)</strong><em>Confidentiality</em>.All aspects of the arbitration proceeding, including but not limited to the award of the arbitrator and compliance therewith, shall be strictly confidential.The parties agree to maintain confidentiality unless otherwise required by law.This paragraph shall not prevent a party from submitting to a court of law any information necessary to enforce this Agreement, to enforce an arbitration award, or to seek injunctive or equitable relief.</p>
      <p><strong>(j)</strong><em>Severability</em>.If any part or parts of this Arbitration Agreement are found under the law to be invalid or unenforceable by a court of competent jurisdiction, then such specific part or parts shall be of no force and effect and shall be severed and the remainder of the Agreement shall continue in full force and effect.</p>
      <p><strong>(k)</strong><em>Right to Waive.</em>Any orall ofthe rights and limitations set forth in this Arbitration Agreement may be waived by the party against whom the claim is asserted.Such waiver shall not waive or affect any other portion of this Arbitration Agreement.</p>
      <p><strong>(l)</strong><em>Survival of Agreement</em>.This Arbitration Agreement will survive the termination of your relationship with Company.</p>
      <p><strong>(m)</strong><em>Emergency Equitable Relief</em>.Notwithstanding the foregoing, either party may seek emergency equitable relief before a state or federal courtin order tomaintain the status quo pending arbitration.A request for interim measures shall not be deemed a waiver of any other rights or obligations under this Arbitration Agreement.</p>
      <p><strong>(n)</strong><em>Claims Not Subject to Arbitration.</em>Notwithstanding the foregoing, claims of defamation, violation of the Computer Fraud and Abuse Act, and infringement or misappropriation of the other party’s patent, copyright,trademarkor trade secrets shall not be subject to this Arbitration Agreement.</p>
      <p><strong>(o)</strong><em>Courts.</em>In any circumstances where the foregoing Arbitration Agreement permits the parties to litigate in court, the parties hereby agree to submit to the personal jurisdiction of the courts located within New York, New York, for such purpose.</p>
      <p><strong>10.3 Export.</strong>The Site may be subject to U.S. export control laws and may be subject to export or import regulations in other countries. You agree not to export, reexport, or transfer, directly or indirectly, any U.S. technical data acquired from Company, or any products utilizing such data, in violation of the United States export laws or regulations.</p>
      <p><strong>10.4 Disclosures.</strong>Company is located at the address in Section 10.8. If you are a California resident, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Product of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.</p>
      <p><strong>10.5 Electronic Communications.</strong>The communications between you and Company use electronic means, whether you use the Site or send us emails, or whether Company posts notices on the Site or communicates with you via email. For contractual purposes, you (a) consent to receive communications from Company in an electronic form; and (b) agree that all terms and conditions, agreements, notices, disclosures, and other communications that Company provides to you electronically satisfy any legal requirement that such communications would satisfy if itwere bein a hardcopy writing. The foregoing does not affect your non-waivable rights.</p>
      <p><strong>10.6 Entire Terms.</strong>These Terms constitute the entire agreement between you and us regarding the use of the Site. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word “including” means “including without limitation”.If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will beunimpairedand the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law.Your relationship to Company is that of an independent contractor, and neither party is an agent or partner of the other.These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without Company’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void.Company may freely assign these Terms.The terms and conditions set forth in these Terms shall be binding upon assignees.</p>
      <p><strong>10.7 Copyright/Trademark Information</strong>.Copyright © 2024 ("CCA"). All rights reserved.Alltrademarks,logosand service marks (“<strong>Marks</strong>”) displayed on the Site are our property or the property of other third parties. You are not permitted to use these Marks without our prior written consent or the consent of such third party which may own the Marks.</p>
      <p><strong>10.8 Contact Information:</strong></p>
      <p>Legal Department</p>
      <div>Address:</div>
      <div>475 Park Avenue South</div>
      <div>8<sup>th</sup>Floor</div>
      <div>New York, New York 10016</div>
      <div>Telephone: 212-686-6827</div>
      <div>Email:<a href="mailto:hello@ccainc.com" target="_blank"  rel="noreferrer">hello@ccainc.com</a></div>
      

      <p>Last Revised on 11 April 2024</p>
      

      <h1 className="ql-align-center"><strong>HIPAA Addendum</strong></h1>
      <p className="ql-align-center"><strong></strong></p>
      <p>This HIPAA Addendum forms part of the Terms governing your use of the Site.In the course ofproviding services under the Terms, CCA may access, use, disclose, store, and/or process PHI on behalf of you or your employer (“Customer” and together with CCA, the “Parties”).This addendum reflects the agreement between the Parties of how CCA will use and/or disclose Customer PHI.As HIPAA or other laws change from time to time, the Terms and this Addendum may also change.Capitalized terms not defined herein shall have the meanings assigned to such terms in the Terms or HIPAA.</p>
      
      <p><strong>1.Permitted Uses</strong></p>
      
      <p>CCA may use and disclose PHI necessary to perform its obligations to the Customer as set out in the Terms, this Addendum, or as otherwise permitted or required under HIPAA. CCA may also: (a) use PHI (i) as necessary for its proper management and administration, or (ii) to carry out its legal responsibilities; (b) disclose PHI to third parties for the same purposes so long as (i) the disclosure is required by law or (ii) the CCA obtains satisfactory assurances from said third party that the PHI will be held confidentially and used or further disclosed only as required by law or for the purpose for which it was disclosed and that the third party will notify the CCA of any instances of which it is aware in which the confidentiality of the PHI has been breached; (c) to provide data aggregation services to you; and (d) to de-identify PHI in accordance with HIPAA.CCA shall only use, disclose, and request the Minimum Necessary PHI to accomplish the purpose of the use,disclosureor request.</p>
      <p><strong></strong></p>
      <p><strong>2.CCA Obligations</strong></p>
      
      <p>(a)<u>Disclosures</u>. CCA agrees not to use or disclose PHI other than as permitted under the Terms or as required by law. CCA agrees to use commercially reasonable efforts to mitigate any harmful effect that is known to CAA of a use or disclosure of PHI by CCA in violation of the requirements of this Addendum.</p>
      <p>(b)<u>Safeguards</u>. CCA agrees to use commercially reasonable efforts to implement administrative, physical, and technical safeguards that reasonably and appropriately protect the confidentiality, integrity, and availability of the PHI that it creates, receives, stores, maintains or transmits on behalf of the Customer, and shall prevent the use or disclosure of Customer’s PHI other than as provided for in the terms.</p>
      <p>(c)<u>Subcontractors</u>. CCA agrees to ensure that any agents, including a subcontractor, to whom CCA provides PHI received from, or created or received by, CCA on behalf of the Customer, agree to restrictions and conditions with respect to use and disclosure of PHI that are no less restrictive than those that apply to CCA under this Addendum.</p>
      <p>(d)<u>Access to PHI</u>. Within fifteen (15) days of receiving a written request from Customer or an individual for a copy of PHI within a Designated Record Set, CCA agrees to make the requested PHI available to the Customer.CCA is required to comply with the Security Rule with respect to electronic PHI, including but not limited to, making available upon written request, copies of PHI in electronic format, when PHI is stored electronically. Upon receipt of the PHI, any disclosure of, or decision not to disclose the PHI requested by an individual and compliance with the requirements applicable to an individual’s right to access PHI shall be the sole responsibility of the Customer.</p>
      <p>(e)<u>Changes to PHI</u>. Within fifteen (15) days of receiving a written request from the Customer to make a change to PHI within a Designated Record Set, the CCA will make such change and will inform the Customer of the change.If CCA receives a change request directly from an individual, CCA shall notify Customer of the request and make said change within fifteen (15) days of receiving a written request from the individual.</p>
      <p>(f)<u>Disclosure Record</u>.Within thirty (30) days of receiving a written request from Customer for a disclosure record of PHI about an individual, CCA shall provide to the Customer with a listing of the persons or entities to which CCA has disclosed PHI about the individual within the prior six (6) years, along with the dates of, reasons for, and brief descriptions of the disclosures to enable the Customer to respond to an individual seeking an record of the disclosures of the individual’s PHI in accordance with 45 C.F.R. § 164.528.CCA shall document such disclosures of PHI and other information related to those disclosures.</p>
      <p>(g)<u>Books and Records</u>.CCA shall make its internal practices, books, and records relating to the use and disclosure of PHI received from, created by, or received by CCA on behalf of the Customer available upon request to the Secretary of the U.S. Department of Health and Human Services so that it may evaluate the Customer’s compliance with the Privacy Rule.</p>
      <p>(h)<u>CCA Obligations Upon Termination</u>. CCA shall, upon termination or expiration of this Addendum, if feasible, return or allow Customer to destroy all PHI received from, processed by, or received by CCA on behalf of Customer, that CCA still maintains in any form in connection with this Addendum through deletion and CCA shall retain no copies of such PHI except as otherwise set forth in this Addendum or the Terms. If such return or destruction is not feasible as determined by CCA, CCA will extend the protections of this Addendum to the PHI and limit further uses and disclosures of the PHI to those that are required by law.</p>
      <p>(i)<u>Security Incident</u>. CCA shall report to the Customer any Security Incident of which it becomes aware.Under 45 C.F.R. § 164.304, a Security Incident is defined as the attempted or successful unauthorized access, use, disclosure, or destruction of information or interference with system operations in an information system.Notwithstanding the foregoing, the Parties acknowledge and agree that this Section constitutes notice by CCA to Customer of the ongoing existence and occurrence of attempted but Unsuccessful Security Incidents.Unsuccessful Security Incidents shall include, but not be limited to, pings and other broadcast attacks on CCA’s firewall, port scans, unsuccessful log-on attempts, denials of service and any combination of the above, or through any other mechanism so long as no such incident results in unauthorized access, use or disclosure of PHI.</p>
      
      <p><strong>3.Breach Notification</strong></p>
      
      <p>(a)<u>Reporting of Breach of PHI</u>. CCA agrees to report to Customer any breach of PHI. Such notification shall promptly be made to the Customer in writing but in no event not later than thirty (30) days from the date that CCA became aware of such breach, or by exercising reasonable diligence should have known of such breach.Furthermore, in the event of a breach of PHI, CCA shall mitigate, to the extent practicable, any harmful effects of said breach.</p>
      <p>(b)<u>Instructions for Reporting a Breach of PHI</u>. Upon CCA’s knowledge of a breach of PHI, CCA will notify Customer, and in such notification, the CCA shall include, to the extent known and available, the following information: (1) a brief description of what happened, including the date of the incident and the date of the discovery of the incident; (2) the identification of each individual whose PHI was disclosed or potentially disclosed; (3) a description of the types of PHI that were involved in the incident; (4) any steps individuals should take to protect themselves from potential harm resulting from the incident; and (5) a brief description of what CCA is doing to investigate the incident, to mitigate the incident, and to protect against any further incidents. If any such information is not available at the time of the notification, CCA shall reasonably work with Customer to provide further information as promptly as information becomes available.</p>
      
      <p><strong>4.Term</strong></p>
      
      <p>(a)This Addendum shall become effective when you register to use the CCA Workplace Assist application and shall terminate upon the termination or expiration of this Addendum, your agreement with CCA, or when all PHI processed or received by CCA on behalf of Customer is, destroyed or returned to Customer or, if the Parties determine that it is not feasible to return or destroy the PHI, protections are extended to such information, in accordance with the terms of the Addendum.</p>
      <p>(b)Notwithstanding any other provision of any agreement, either Party may immediately terminate this Addendum if either Party, acting reasonably, makes the determination that the other Party has breached a material term of this Addendum and has failed to remedy such breach within thirty (30) days after receipt of written notice thereof. At the termination, CCA shall, if feasible, return or allow the Customer to destroy all PHI upon return or deletion retain no copies of such information except for one copy used solely for evidence purposes and/or for the establishment, exercise, or defense of legal claims and/or for compliance with legal obligations.</p>
      
      <p><strong>5. Obligations of Customer</strong></p>
      <p>(a)<u>Authority</u>.Customer has the full right, power, and authority to agree to this Addendum and has obtained and will obtain the proper consents, authorizations, and/or other legal permissions required under HIPAA for the disclosure of PHI to CCA.</p>
      <p>(b)<u>Revocation of Permitted Use or Disclosure of PHI</u>. Customer shall provide CCA with any changes in, or revocation of, permission by an individual to use or disclose PHI, if such changes affect CCA’s permitted or required uses and disclosures.</p>
      <p>(c)<u>Restrictions on Use or Disclosure of PHI</u>. Customer shall notify CCA, in writing, of any restriction to the use or disclosure of PHI.</p>
      <p>(d)<u>Minimum Necessary</u>. Customer shall provide to the CCA only the minimum PHI necessary for CCA to perform or fulfill a specific function required or permitted hereunder.CCA agrees that requests, uses, or disclosures shall be to the minimum extent necessary, including the use of a limited data set to accomplish the limited purpose of such request, use, or disclosure.</p>
      
      <p><strong>6.Miscellaneous</strong></p>
      
      <p>(a)<u>Integration andSharing</u>. CCA permits the integration, sharing, and exchange of information with third party services that may or may not be compliant with HIPAA. If Customer chooses to use suchthird partyservices Customer is solely liable and responsible for the exchange of information, including any PHI between CCA’s services and the third party. Third party providers are not business associates of CCA and CCA hereby expressly disclaims any liability for any use, disclosure, or other action taken by suchthird partyproviders or any noncompliance by Customer with any applicable law, regulation, or contractual provision relating to the sharing of information, including PHI, with any such third party.</p>
      <p>(b)<u>Modifications to Addendum</u>. The Parties agree to modify this Addendum to the extent necessary to allow either Party to comply with HIPAA as amended or other related regulations or statutes. CCA may amend this Addendum for minor edits (e.g., typos, grammatical edits and/or non-material edits) with or without notice to Customer.CCA will notify Customer of any material edits to this Addendum.</p>
      <p>(c)<u>Audits</u>. If and to the extent required to comply with applicable law, CCA shall provide to Customer access during business hours and upon reasonable prior written notice (of no less than fourteen (14) days) to, and prior coordination with, CCA’s personnel, to CAA’s records directly relevant to audit CCA’s compliance with its obligations under this Addendum. CAA shall provide any assistance reasonably requested by Customer or its designee in conducting any such audit.</p>
      <p>(d)<u>No Third-Party Beneficiaries</u>. Nothingexpressor implied in this Addendum is intended to confer, nor shall anything herein confer, upon any person other than the Parties and their respective successors and assigns, any rights, remedies,obligationsor liabilities whatsoever.</p>
      <p>(e)<u>Notices</u>.All notices required to be given to either Party will be in writingandsent by traceable carrier, which includes via email with confirmation of receipt.Notices will be effective upon receipt.</p>
      <p>(f)<u>Governing Law</u>. This Addendum shall be governed by and construed in accordance with the laws New York, without regard to its conflict oflawsprovisions.</p>
      
      
    </div>
  );
};

export default TermsOfUse;
