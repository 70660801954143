import FixedHeaderUserLayout from "../../layout/FixedHeaderUserLayout";
import BackButtonBlock from "../../../routes/BackButtonBlock";
import UserInformation from "./UserInformation";

const index: React.FC = () => {
  return (
    <div>
      <BackButtonBlock />
      {/* <FixedHeaderUserLayout children={<UserInformation />} /> */}
    </div>
  );
};
export default index;
