import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './SplashScreen.scss'
import EnvironmentConfig from '../../component/config/EnvironmentConfig';

const SplashScreen: React.FC = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className='splash-screen-page'>
      <Slider {...settings}>
        <img src={`${EnvironmentConfig.imageBaseURL}slide1.jpg`} alt='splash-image' />
        <img src={`${EnvironmentConfig.imageBaseURL}slide2.jpg`} alt='splash-image' />
        <img src={`${EnvironmentConfig.imageBaseURL}slide3.jpg`} alt='splash-image' />
        <img src={`${EnvironmentConfig.imageBaseURL}slide4.jpg`} alt='splash-image' />
        <img src={`${EnvironmentConfig.imageBaseURL}slide5.jpg`} alt='splash-image' />
        <img src={`${EnvironmentConfig.imageBaseURL}slide6.jpg`} alt='splash-image' />
      </Slider>
    </div>
  )
}

export default SplashScreen