import React, { useState, useEffect, useRef } from 'react';
import './LessonAndSidebar.scss'
import ModuleSidebar from '../SideTabs/module-sidebar/ModuleSidebar'
import { useLocation, useParams } from 'react-router-dom';
import { getCBTSectionLessons, updateLessonProgress } from '../../../utils/ApiClient';
import Loader from '../Loader/Loader';
import { FetchApiData } from '../../../services/useFetchData';
import LessonModule from './LessonModule';
import { findFirstIncompleteLesson, findSectionObjectById, getNextLesson } from '../../../utils/helperFunctions';
import useGlobalState from '../../../context/useGlobalState';
import { Sections } from '../../../interfaces/ApiTypes';
import axios from 'axios';
import useHandleUrlIdError from '../../../utils/handleUrlIdError';

const LessonAndSidebar: React.FC = () => {
  const { moduleId, lessonId } = useParams();
  const effectRef = useRef(false)
  const [cbtModuleSections, setcbtModuleSections] = useState<Sections[]>([]);
  const [sectionId, setSectionId] = useState<number | null>();
  const { fetchLesson, lessonDetails, isLessonFetched, currentLessonId } = FetchApiData()
  const { globalErrorHandler, loader, setLoader } = useGlobalState()
  const handleUrlIdError = useHandleUrlIdError();
  const location = useLocation();

  useEffect(() => {
    const fetchSections = async (): Promise<void> => {
      try {
        const response = await getCBTSectionLessons(Number(moduleId))
        setcbtModuleSections(response.data)
        if (lessonId) {
          const result = findSectionObjectById(response.data, Number(lessonId))
          setSectionId(result?.lessonGroup.id ?? null)
          fetchLesson(Number(moduleId), Number(lessonId))
        } else {
          const sectionIds = findFirstIncompleteLesson(response.data)
          setSectionId(sectionIds?.sectionId ?? null)
          fetchLesson(Number(moduleId), sectionIds?.lessonId ?? null)
        }
        setLoader(false)
      } catch (error) {
        setLoader(false)
        if (axios.isAxiosError(error)) {
          handleUrlIdError(error);
        } else {
          globalErrorHandler(error)
        }
      }
    }
    if (effectRef.current === false) {
      fetchSections()
    }
    return () => {
      effectRef.current = true
    }
  }, [moduleId])

  const handleNextButton = async (id: number, status: boolean): Promise<void> => {
    try {
      await updateLessonProgress(id, status)
      if (lessonDetails) {
        const nextLessonId = getNextLesson(cbtModuleSections, lessonDetails?.id)
        setSectionId(nextLessonId?.sectionId ?? null)
        if (nextLessonId !== null) {
          fetchLesson(Number(moduleId), nextLessonId.lessonId)
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        handleUrlIdError(error);
      } else {
        globalErrorHandler(error)
      }
    }
  }

  const handleOnLessonClick = (id: number) => {
    const isModulePage = /^\/app\/module\/\d+$/.test(location.pathname);
    if (isModulePage) return;
    fetchLesson(Number(moduleId), id);
  };

  return (
    <>
      {
        loader ? <Loader /> : <>
          {
            cbtModuleSections && <div className='lesson-sidebar-component'>
              <div className='sidebar-section'>
                <ModuleSidebar
                  id={Number(moduleId)}
                  sectionId={sectionId}
                  setSectionId={setSectionId}
                  currentLessonId={currentLessonId}
                  cbtModuleSections={cbtModuleSections}
                  handleOnLessonClick={handleOnLessonClick} />
              </div>
              <div className='module-content-grid'>
                {
                  lessonDetails &&
                  <LessonModule
                    moduleId={Number(moduleId)}
                    onLessonUpdated={fetchLesson}
                    isLessonFetched={isLessonFetched}
                    currentLessonDetails={lessonDetails}
                    handleNextButton={handleNextButton} />
                }
              </div>
            </div>
          }
        </>
      }
    </>
  )
}

export default LessonAndSidebar