import React, { useState } from "react";
import "../../../styles/customRadioButton.scss";
import "./SupportRequest.scss";
import { supportRequest, common } from "../../../utils/constants";
import { useNavigationHandler } from "../../../utils/useNavigationHandler";

interface Props {
  onNext: (answer: string) => void;
}

const SupportRequest: React.FC<Props> = ({ onNext }) => {
  const [error, setError] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("");
  const services = localStorage.getItem("available-service");
  const { navigateWithOption } = useNavigationHandler();

  const handleSubmit = async (button: string): Promise<void> => {
    if (button === common.NEXT && !selectedOption) {
      setError(true);
    } else {
      onNext(selectedOption)
      navigateWithOption(button, selectedOption);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setSelectedOption(e.target.value);
  };

  return (
    <div className="support-request-page">
      <div className="page-container">
        <span className="subtitle">{supportRequest.SUBTITLE}</span>
        <span className="title">{supportRequest.TITLE}</span>
        <div className="radio-box-container">
          {supportRequest.OPTIONS?.map((item, index) => {
            return (
              <div className="options" key={"option" + index}>
                <input
                  type="radio"
                  id={item}
                  value={item}
                  className="radio-input"
                  checked={selectedOption === item}
                  onChange={handleChange}
                />
                <label className="label-text" htmlFor={item}>
                  {item}
                </label>
              </div>
            );
          })}
        </div>
        {error && <span className="error">{supportRequest.ERROR_SHOW}</span>}
        <div className="question-button-div">
          <button
            className="green-rounded-btn"
            onClick={() => handleSubmit(common.NEXT)}
          >
            {common.NEXT}
          </button>
          <button
            className={
              services?.includes(common.SELF_SCHEDULING)
                ? "green-rounded-btn"
                : "hide-div"
            }
            onClick={() => handleSubmit(common.COUNSELING_SUPPORT)}
          >
            {common.COUNSELING_SUPPORT}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SupportRequest;
