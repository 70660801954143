import "./AgeGate.scss";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { CCADatePicker } from "../../common/CCADatePicker/CCADatePicker";
import { ageGate, common, storageKeys } from "../../../utils/constants";
import { calculateAge } from "../../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";

interface AgeGateProps {
  onNext: (answer: string) => void;
}

const AgeGate: React.FC<AgeGateProps> = ({ onNext }) => {
  const [birthDate, setBirthDate] = useState<Date | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isValidDate, setIsValidDate] = useState<boolean>(false);
  const name: string | null = localStorage.getItem(storageKeys.FirstName) || "";
  const navigate = useNavigate();
  const today = new Date();

  useEffect(() => {
    if (birthDate === null || isNaN(birthDate.getTime()) || birthDate > new Date()) {
      setIsValidDate(false);
      if (birthDate !== null && birthDate > new Date()) {
        setError(ageGate.ERROR);
      }
    } else {
      setIsValidDate(true);
    }
  }, [birthDate]);

  const handleDateChange = (date: Date | null) => {
    setError(null);
    setBirthDate(date);
  };

  const handleConfirm = () => {
    if (isValidDate) {
      const _birthDate = moment(birthDate).format("DD-MM-YYYY");
      const mm_dd_yy = moment(birthDate).format("MM-DD-YYYY");
      const age: number = calculateAge(_birthDate);
      if (age < 18) {
        localStorage.setItem(storageKeys.RegistrationBlock, "true");
        navigate("/call-page");
      } else {
        onNext(mm_dd_yy)
      }
    }
  };

  const setDefaultDate = () => {
    // Calculate the date 18 years ago from today
    if (!birthDate) {
      const defaultBirthDate = new Date();
      defaultBirthDate.setFullYear(defaultBirthDate.getFullYear() - 18);
      setBirthDate(defaultBirthDate);
    }
  };

  return (
    <div className="page-center-container margin-content age-gate-page">
      <span className="subtitle">{ageGate.SUBTITLE}</span>
      <span className="title">
        Hello&nbsp;{name.charAt(0).toUpperCase() + name.substring(1)}!
      </span>
      <span className="title">{ageGate.QUESTION}</span>
      <div className="date-picker">
        <CCADatePicker
          value={birthDate}
          placeholder="Enter your birthdate"
          onChange={(date: Date | null) => handleDateChange(date)}
          onClick={setDefaultDate}
          maxDate={today}
        />
      </div>
      {error && <span className="error">{error}</span>}
      <div className="button-div">
        <button
          className={isValidDate ? "green-rounded-btn" : "disabled-rounded-btn"}
          onClick={handleConfirm}
          disabled={!isValidDate}
        >
          {common.NEXT}
        </button>
      </div>
    </div>
  );
};

export default AgeGate;
