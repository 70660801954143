import React, { useEffect, useState, useRef } from "react";
import SendbirdChat from "@sendbird/chat";
import {
  GroupChannelModule,
  GroupChannelHandler,
} from "@sendbird/chat/groupChannel";
import SendBirdDesk from "sendbird-desk";
import {
  MessageCollectionInitPolicy,
  MessageFilter,
} from "@sendbird/chat/groupChannel";
import { useParams } from "react-router-dom";
import OutlinedInput from "@mui/material/OutlinedInput";
import { RefreshToken } from "../../../utils/sessionToken";
import { common, chat, storageKeys } from "../../../utils/constants";
import { getTicketInfo } from "../../../utils/ApiClient";
import { formateDate, imageSrc } from "../../../utils/helperFunctions";
import Loader from "../../common/Loader/Loader";
import EnvironmentConfig from "../../config/EnvironmentConfig";
import Send from "../../assests/icons/send.png";
import * as Sentry from "@sentry/react";
import { clearLocalStorageExceptKeys } from "../../../utils/clearStorageData";
import "./Chat.scss";
import useGlobalState from "../../../context/useGlobalState";

const NewMessage: React.FC = () => {
  const [sentMessages, setsentMessages] = useState<string>("");
  const channelurl: any = useRef(null);
  const [messages, setMessages] = useState<any>([]);
  const [agentName, setAgentName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [sessionToken, setSessionToken] = useState<string>("");
  const chatDivRef: any = useRef<HTMLDivElement | null>(null);
  const userId: any = localStorage.getItem(storageKeys.UserID);
  const [channelUrl, setChannelUrl] = useState<string>("");
  const [buttonDisable, setButtonDisable] = useState<boolean>(false);
  const { ticketId } = useParams<any>();
  const [error, setError] = useState<boolean>(false);
  const { globalErrorHandler } = useGlobalState()

  function handleMessageReceived(channel: any, message: any) {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        scrollToBottom();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    SendBirdDesk.Ticket.getByChannelUrl(channel.url, (res: any, err: any) => {
      if (err) {
        console.log("err", err);
        Sentry.captureException(err);
        window.location.reload();
      } else {
        const ticket = res;
        if (message.data) {
          let resObj = JSON.parse(message.data);
          if (
            resObj.type === "NOTIFICATION_MANUAL_CLOSED" ||
            resObj.type === "SYSTEM_MESSAGE_TICKET_CLOSED_BY_AGENT"
          ) {
            setButtonDisable(true);
          } else {
            setButtonDisable(false);
          }
        }
        if (message.messageType === "user") {
          let receivedMessage = message.message;
          const newMessage: any = {
            message: receivedMessage,
            sender: message.sender,
            latestMessage: true,
            createdAt: new Date(),
          };
          setMessages((oldMessages: any) => [...oldMessages, newMessage]);
          if (ticket.channel.url === channel.url) {
            ticket.channel.markAsRead(() => { });
          }
        }
      }
    });
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }

  function processMessages(messages: Array<any>) {
    const userMessages = messages.filter(
      (message: any) => message.messageType === "user"
    );
    let sortedArray: any = userMessages.reverse();
    setMessages(sortedArray);
    setLoading(false);
  }

  function getChannelUrlCallback(res: any, err: any) {
    if (err) {
      console.log("error", err);
      setLoading(false);
      setError(true);
      Sentry.captureException(err);
    } else {
      if (res.status === "CLOSED") {
        setButtonDisable(true);
      }
      const ticket: any = res;
      setAgentName(ticket.agent ? ticket.agent.name : "Brittany Griggs");
      const filter: MessageFilter = new MessageFilter();
      const startingPoint: number = Date.now();
      const collection = ticket.channel.createMessageCollection({
        filter,
        startingPoint,
      });

      collection
        .initialize(MessageCollectionInitPolicy.CACHE_AND_REPLACE_BY_API)
        .onCacheResult((err: any, messages: any) => {
          if (err) {
            Sentry.captureException(err);
          }
        })
        .onApiResult((err: any, messages: any) => {
          if (err) {
            Sentry.captureException(err);
            return;
          }
          processMessages(messages);
          if (ticket.channel.url === channelurl.current) {
            ticket.channel.markAsRead(() => { });
          }
        });

      return collection.loadPrevious();
    }
  }

  const handleInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setsentMessages(event.target.value);
  };
  const loadInitialMessages = async () => {
    try {
      const response = await getTicketInfo(ticketId ?? "");
      const status = response.data.isTicketOpen;
      //TODO: Handle no channelUrl properly
      const channelUrl = response.data.channelUrl ?? "";
      setChannelUrl(channelUrl);
      channelurl.current = channelUrl;
      setButtonDisable(!status);
      await SendBirdDesk.Ticket.getByChannelUrl(
        channelurl.current,
        getChannelUrlCallback
      );
    } catch (error: any) {
      globalErrorHandler(error);
    }
  };

  useEffect(() => {
    if (!sessionToken) {
      return;
    }

    const params = {
      appId: `${EnvironmentConfig.sendbirdAppId}`,
      modules: [new GroupChannelModule()],
      sessionToken: sessionToken,
    };

    // Initialize Sendbird
    const _sendbird: any = SendbirdChat.init(params);

    (async () => {
      // Connect to Sendbird, make sure we wait for it to connect before continuing
      try {
        await _sendbird.connect(userId, sessionToken);
        clearLocalStorageExceptKeys();
      } catch (error: any) {
        globalErrorHandler(error);
        console.log("Senbird Connect Error ", error.message);
      }

      const groupChannelHandler: GroupChannelHandler = new GroupChannelHandler({
        onMessageReceived: (channel: any, message: any) => {
          handleMessageReceived(channel, message);
        },
      });

      _sendbird.groupChannel.addGroupChannelHandler(
        "groupChannelHandler",
        groupChannelHandler
      );
    })();

    // Initialize Sendbird Desk
    try {
      SendBirdDesk.init(_sendbird);
      SendBirdDesk.authenticate(userId, sessionToken, loadInitialMessages);
    } catch (error) {
      setError(true);
      console.log("error while initialize desk", error);
    }

    return () => {
      _sendbird.groupChannel.removeGroupChannelHandler("groupChannelHandler");
      _sendbird.disconnect().catch((error: any) => {
        globalErrorHandler(error);
        console.log("Error", error);
      });
    };
  }, [sessionToken]);


  const scrollToBottom = () => {
    if (chatDivRef.current) {
      const behavior: ScrollBehavior = "smooth";
      chatDivRef.current.lastChild.scrollIntoView({ behavior });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    RefreshToken().then(setSessionToken).catch(globalErrorHandler);
  }, []);

  const handleMessage = () => {
    SendBirdDesk.Ticket.getByChannelUrl(channelUrl, (ticket: any, err: any) => {
      if (err) {
        Sentry.captureException(err);
        console.log("Ticket error response", err);
        window.location.reload();
      } else {
        if (sentMessages.trim() !== "") {
          const message = {
            message: sentMessages,
          };
          ticket.channel
            .sendUserMessage(message)
            .onSucceeded(() => {
              setMessages([
                ...messages,
                {
                  message: sentMessages,
                  sender: { userId: userId },
                  createdAt: new Date(),
                },
              ]);
              setsentMessages("");
            })
            .onFailed((error: any) => {
              console.log("send message error", error);
            });
        }
      }
    });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter") {
      if (!event.shiftKey) {
        event.preventDefault();
        if (!buttonDisable) {
          handleMessage();
        }
      }
    }
  };

  return (
    <div className="chat-page">
      {error ? (
        <div className="loader-container">
          <span>{common.CONTCAT_SUPPORT}</span>
        </div>
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <div className="chat-div">
              <div className="message-list" ref={chatDivRef}>
                {messages?.map((msg: any, index: any) => {
                  let lastIndex = messages.length - 1;
                  let condition =
                    lastIndex === index && msg.hasOwnProperty("latestMessage");
                  const customClassName = condition
                    ? "latest-message"
                    : "chat-message";
                  const time = formateDate(msg.createdAt);
                  return (
                    <div className="messags-box" key={"msg" + index}>
                      <div className="user-info-div">
                        <div className="user-profile">
                          <div className="profile-container">
                            <img
                              src={imageSrc(msg.sender)}
                              alt="profile"
                              className="profile-pic"
                            />
                          </div>
                          <span
                            className={
                              msg.sender && msg.sender.userId === userId
                                ? "receiver"
                                : "sender"
                            }
                          >
                            {msg.sender && msg.sender.userId === userId
                              ? `${localStorage.getItem(
                                storageKeys.FirstName
                              )} 
                      ${localStorage.getItem(storageKeys.LastName)}`
                              : msg.sender && msg.sender.userId
                                ? agentName
                                : ""}
                          </span>
                        </div>
                        <span className="time-text">{time}</span>
                      </div>
                      <div className="msg-div">
                        {msg.messageType === "admin" ||
                          msg.sender === undefined ? (
                          <div
                            className={customClassName}
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {msg.message}
                          </div>
                        ) : (
                          <div>
                            <div
                              className={customClassName}
                              style={{ whiteSpace: "pre-wrap" }}
                            >
                              {msg.message}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="chat-footer">
                <OutlinedInput
                  multiline
                  disabled={buttonDisable}
                  rows={Math.ceil(sentMessages.length / 150)}
                  value={sentMessages}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                  placeholder={
                    buttonDisable ? chat.DISABLELABEL : chat.ACTIVE_LABEL
                  }
                  className="chat-input"
                />
                <div className="button-set">
                  <button
                    className={
                      buttonDisable ? "send-button-disable" : "send-button"
                    }
                    onClick={handleMessage}
                    disabled={buttonDisable}
                  >
                    <span className="respons-text">{common.SEND}</span>
                    <span className="respons-icon">
                      <img src={Send} alt="send" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NewMessage;
